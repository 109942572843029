import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { fetchTenants, fetchUser, setActiveTenant } from 'features';
import { UserInfoResponse, UserSliceState } from 'interfaces';
import { TenantId } from 'enums';

const initialState: UserSliceState = {
  currentTenant: null,
  allTenants: [],
  tenantErrorMessage: '',
  isLoggedIn: false,
  loading: 'idle',
  loadingSetTenant: 'idle',
  email: '',
};

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    logOut: (state) => {
      state.isLoggedIn = false;
    },
    resetLoadingSetTenant: (state) => {
      state.loadingSetTenant = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.loading = 'loading';
    });

    builder.addCase(fetchUser.fulfilled, (state, action: PayloadAction<UserInfoResponse>) => {
      state.loading = 'loaded';
      if (!action.payload) {
        return;
      }
      state.email = action.payload.email;
      state.isLoggedIn = typeof action.payload !== 'string';
    });

    builder.addCase(fetchUser.rejected, (state) => {
      state.loading = 'error';
    });

    builder.addCase(setActiveTenant.fulfilled, (state, action: PayloadAction<TenantId>) => {
      state.currentTenant = action.payload;
      state.loadingSetTenant = 'loaded';
    });

    builder.addCase(setActiveTenant.rejected, (state, action) => {
      state.tenantErrorMessage = action?.payload?.error || '';
      state.loadingSetTenant = 'error';
    });

    builder.addCase(fetchTenants.fulfilled, (state, action: PayloadAction<Array<string>>) => {
      state.allTenants = action.payload;
    });
  },
});

export const selectCurrentTenant = ({ user }: RootState): TenantId | null => user.currentTenant;
export const selectAllTenants = ({ user }: RootState): Array<string> => user.allTenants;
export const selectTenantErrorMessage = ({ user }: RootState): string => user.tenantErrorMessage;
export const selectIsUserLoggedIn = ({ user }: RootState): boolean => user.isLoggedIn;
export const selectLoadingUser = ({ user }: RootState): string => user.loading;
export const selectLoadingSetTenant = ({ user }: RootState): string => user.loadingSetTenant;

export const { logOut, resetLoadingSetTenant } = userSlice.actions;
