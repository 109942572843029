import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBusStops, editBusStop, deleteBusStop, getOsmBusStops } from 'api';
import { EditBusStopRequest, DeleteBusStopRequest } from 'interfaces';

export const fetchBusStops = createAsyncThunk('busStops/fetch', async () => {
  const { data } = await getBusStops();
  return data;
});

export const handleEditBusStop = createAsyncThunk('busStops/edit', async (requestData: EditBusStopRequest) => {
  const { data } = await editBusStop(requestData);
  return data;
});

export const handleDeleteBusStop = createAsyncThunk('busStops/delete', async (requestData: DeleteBusStopRequest) => {
  const { data } = await deleteBusStop(requestData);
  return data;
});

export const fetchOsmBusStops = createAsyncThunk('osmBusStops/fetch', async () => {
  const { data } = await getOsmBusStops();
  return data;
});
