import { getBackup } from 'api';
import store from 'store';
import { handleUploadBackup } from 'features';

export const downloadBackup = async () => {
  const { data } = await getBackup();
  const blob = new Blob([JSON.stringify(data)], { type: 'octet/stream' });
  const url = URL.createObjectURL(blob);
  const el = document.createElement('a');
  el.href = URL.createObjectURL(blob);
  el.download = `backup__${new Date().toISOString()}.json`;
  el.click();
  URL.revokeObjectURL(url);
};

export const uploadBackup = () => {
  const el = document.createElement('input');
  const reader = new FileReader();
  el.type = 'file';
  el.click();
  el.onchange = (event) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) reader.readAsText(file);
  };
  reader.onload = async (event) => {
    const json = event.target?.result as string;
    if (json) store.dispatch(handleUploadBackup(JSON.parse(json)));
  };
};
