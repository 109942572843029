import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Coordinate } from 'ol/coordinate';
import { toLonLat } from 'ol/proj';
import { Edge } from 'interfaces';
import {
  fetchOsmexport,
  selectLoadingOsmexport,
  resetLoadingOsmexport,
  selectOsmexportEdges,
  selectLoadingCreateIntersection,
  setSelectedEdgesNames,
  setSelectedEdgesCoords,
  setSelectedEdgesMaxSpeed,
} from 'features';
import { MapUtils } from 'components/EditGrid/utils';
import { LayerName } from 'components/EditGrid/types';

export const useOsmEdges = (map: MapUtils | undefined) => {
  /* ---------------------------------- Hooks --------------------------------- */

  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const loadingOsmExport = useSelector(selectLoadingOsmexport);
  const osmEdgesGeoJSON = useSelector(selectOsmexportEdges);
  const loadingCreateIntersection = useSelector(selectLoadingCreateIntersection);
  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */
  const selection = map?.selectedFeatures[LayerName.OSM_EDGES];

  /* -------------------------------- Functions ------------------------------- */
  const handleOsmeexportLoaded = () => {
    map?.setLayer({ name: LayerName.OSM_EDGES, geojson: osmEdgesGeoJSON });
  };

  const dispatchSelectedData = () => {
    if (!selection) return;
    const features = selection.getArray();
    const names: Edge['name'][] = [];
    const edges: Coordinate[][] = [];
    let maxSpeed = '';
    features.forEach((feature) => {
      const name = feature.get('name');
      const msToKmhMultiplier = 3.6;
      const speed = String(Math.round(feature.get('speed') * msToKmhMultiplier)); // m/s to km/h
      if (!names.includes(name)) names.push(name);
      if (Number(speed) > Number(maxSpeed)) maxSpeed = speed;
      const coords = feature
        .get('geometry')
        .getCoordinates()
        .map((coord: Coordinate) => toLonLat(coord));
      edges.push(coords);
    });
    dispatch(setSelectedEdgesNames(names));
    dispatch(setSelectedEdgesCoords(edges));
    dispatch(setSelectedEdgesMaxSpeed(maxSpeed));
  };

  const initEvents = () => {
    selection?.on('change', dispatchSelectedData);
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (map) {
      dispatch(fetchOsmexport());
      initEvents();
    }
  }, [map]);

  useEffect(() => {
    if (loadingOsmExport === 'loaded') {
      dispatch(resetLoadingOsmexport());
      handleOsmeexportLoaded();
    }
  }, [loadingOsmExport]);

  useEffect(() => {
    if (loadingCreateIntersection === 'loaded') {
      selection?.clear();
    }
  }, [loadingCreateIntersection]);
};
