/* eslint-disable react/jsx-one-expression-per-line */
import { useRef, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Feature, MapBrowserEvent, Overlay } from 'ol';
import OverlayPositioning from 'ol/OverlayPositioning';
import BaseLayer from 'ol/layer/Base';
import { LayerName } from 'components/EditGrid/types';
import { MapUtils } from 'components/EditGrid/utils/mapUtils';
import styles from './Tooltip.module.scss';

interface TooltipProps {
  map: MapUtils;
}

export const Tooltip: React.FC<TooltipProps> = ({ map }) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const tooltipElRef = useRef<HTMLDivElement>(null);

  /* -------------------------------- Selectors ------------------------------- */

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */
  const layersWithTooltip = [LayerName.INTERSECTIONS, LayerName.OSM_EDGES, LayerName.SEGMENTS, LayerName.APPROACHES];

  /* -------------------------------- Functions ------------------------------- */
  const TooltipContent: React.FC<{ feature: Feature; layer: BaseLayer }> = ({ feature, layer }) => {
    const layerName = layer.get('name');
    let features = [feature];
    const clustered = feature.get('features');
    if (clustered) features = clustered;
    return (
      <div className="list" key={layerName}>
        <div className={styles.title}>{layerName}</div>
        {features.map((f: Feature, i: number) => (
          <div className={styles.item} key={i.toString()}>
            {layerName === LayerName.OSM_EDGES && f.get('name')}
            {layerName === LayerName.APPROACHES && <>ID: {f.getId()}</>}
            {layerName === LayerName.INTERSECTIONS && (
              <>
                ID: {f.get('id')} <br />
                Name: {f.get('name')} <br />
                Signalized: {f.get('signalized').toString()}
              </>
            )}
            {layerName === LayerName.SEGMENTS && (
              <>
                Start Intersection: {f.get('fromIntersectionId')} <br />
                End Intersection: {f.get('toIntersectionId')} <br />
                Speed: {f.get('speed')} <br />
              </>
            )}
          </div>
        ))}
      </div>
    );
  };

  const hideTooltip = () => {
    map?.olMap.getOverlayById('tooltip').setPosition(undefined);
  };

  const showTooltip = (layer: BaseLayer, feature: Feature, event: MapBrowserEvent) => {
    const overlay = map?.olMap.getOverlayById('tooltip');
    if (overlay && layer && feature && layersWithTooltip.includes(layer.get('name'))) {
      overlay.setPosition(event.coordinate);
      const element = overlay.getElement();
      if (element) {
        element.innerHTML = ReactDOMServer.renderToString(<TooltipContent feature={feature} layer={layer} />);
      }
    } else {
      hideTooltip();
    }
  };

  const initOverlay = () => {
    const element = tooltipElRef.current as HTMLDivElement;
    const positioning = 'center-left' as OverlayPositioning;
    map?.olMap.addOverlay(
      new Overlay({
        element,
        positioning,
        id: 'tooltip',
        offset: [20, 0],
      }),
    );
  };

  const initEvents = () => {
    map?.on('pointermove', showTooltip);
    map?.on('click', hideTooltip);
    map?.on('contextmenu', hideTooltip);
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    initOverlay();
    initEvents();
  }, []);

  return <div ref={tooltipElRef} className={styles.tooltip} />;
};
