import { createAsyncThunk } from '@reduxjs/toolkit';
import { getGeofenceArea, postGeofenceArea } from 'api';
import { GeofenceAreaRequest } from 'interfaces';

export const fetchGeofenceArea = createAsyncThunk('geofenceArea/fetch', async () => {
  const { data } = await getGeofenceArea();
  return data;
});

export const createGeofenceArea = createAsyncThunk('geofenceArea/create', async (requestData: GeofenceAreaRequest) => {
  const { data } = await postGeofenceArea(requestData);
  return data;
});
