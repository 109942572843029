import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOverlay } from '@axilion/ui-components';
import { selectIsLoading, fetchUser } from 'features';
import { ToastMessage, CamerasList, EditGrid, SchedulesList, Navigation, Header } from 'components';
import { Route, Switch } from 'react-router-dom';
import styles from './App.module.scss';

export const App: React.FC = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const isLoading = useSelector(selectIsLoading);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */

  /* --------------------------------- Effects -------------------------------- */

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  return (
    <div className={styles.app}>
      {isLoading && <LoadingOverlay />}
      <ToastMessage />
      <Header />
      <Navigation />
      <div className={styles.main}>
        <Switch>
          <Route path="/cameras" component={CamerasList} />
          <Route path="/schedules" component={SchedulesList} />
          <Route path="/editgrid" component={EditGrid} />
        </Switch>
      </div>
    </div>
  );
};
