import { useEffect, useState, useRef } from 'react';
import { MapUtils } from 'components/EditGrid/utils';

export const useRerenderFromMap = (map: MapUtils | undefined) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const timeoutRef = useRef<number>();

  /* -------------------------------- Selectors ------------------------------- */

  /* ---------------------------------- State --------------------------------- */
  const [, setTick] = useState(0);

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const render = () => {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => setTick((tick) => tick + 1), 10);
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (map) {
      map.mainGroup.on(['change'], render);
      map.tileLayer.on(['change'], render);
    }
  }, [map]);
};
