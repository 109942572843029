import { LayerName, GroupName } from 'components/EditGrid/types';

export const layersMenuItems: (LayerName | GroupName)[] = [
  LayerName.APPROACHES,
  LayerName.GEOFENCE_AREA,
  LayerName.DETECTION_AREA,
  LayerName.INTERSECTIONS,
  LayerName.SEGMENTS,
  GroupName.CORRIDORS,
  LayerName.BUS_STOPS,
  LayerName.OSM_NODES,
  LayerName.OSM_EDGES,
];
