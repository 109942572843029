import { useSelector, useDispatch } from 'react-redux';
import { DialogName } from 'enums';
import { selectContextSelectedBusStop, handleDeleteBusStop } from 'features';
import { MainDialog } from 'components/EditGrid/components/Dialogs/MainDialog';

export const DeleteBusStop = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const busStop = useSelector(selectContextSelectedBusStop);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  if (!busStop) return null;

  const onSubmit = () => {
    dispatch(handleDeleteBusStop(busStop?.id));
  };

  /* --------------------------------- Effects -------------------------------- */

  return (
    <MainDialog name={DialogName.DELETE_BUSSTOP} onSubmit={onSubmit}>
      {`ID: ${busStop.id}`}
    </MainDialog>
  );
};
