import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { TimeIntervalsSliceState, TimeIntervalsResponse } from 'interfaces';
import { fetchTimeIntervals, editTimeIntervals } from './thunks';

const initialState: TimeIntervalsSliceState = {
  entries: [],
  loading: 'idle',
  loadingEdit: 'idle',
};

export const timeIntervalsSlice = createSlice({
  initialState,
  name: 'timeIntervals',
  reducers: {
    resetLoadingTimeIntervals: (state) => {
      state.loading = 'idle';
    },
    resetLoadingEditTimeIntervals: (state) => {
      state.loadingEdit = 'idle';
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchTimeIntervals.fulfilled, (state, action: PayloadAction<TimeIntervalsResponse>) => {
      state.entries = action.payload;
      state.loading = 'loaded';
    });
    addCase(editTimeIntervals.fulfilled, (state) => {
      state.loadingEdit = 'loaded';
    });
  },
});

export const selectTimeIntervals = ({ timeIntervals }: RootState) => timeIntervals.entries;
export const selectLoadingTimeIntervals = ({ timeIntervals }: RootState) => timeIntervals.loading;
export const selectLoadingEditTimeIntervals = ({ timeIntervals }: RootState) => timeIntervals.loadingEdit;

export const { resetLoadingTimeIntervals, resetLoadingEditTimeIntervals } = timeIntervalsSlice.actions;
