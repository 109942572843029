import { EditTimeIntervals } from './dialogTypes/EditTimeIntervals';
import { CreateGeofenceArea } from './dialogTypes/CreateGeofenceArea';
import { CreateDetectionArea } from './dialogTypes/CreateDetectionArea';
import { CreateCorridor } from './dialogTypes/CreateCorridor';
import { DeleteCorridor } from './dialogTypes/DeleteCorridor';
import { CreateApproach } from './dialogTypes/CreateApproach';
import { DeleteApproach } from './dialogTypes/DeleteApproach';
import { EditBusStop } from './dialogTypes/EditBusStop';
import { DeleteBusStop } from './dialogTypes/DeleteBusStop';
import { CreateIntersection } from './dialogTypes/CreateIntersection';
import { DeleteIntersection } from './dialogTypes/DeleteIntersection';
import { CreateSegment } from './dialogTypes/CreateSegment';
import { DeleteSegment } from './dialogTypes/DeleteSegment';

export const Dialogs = () => {
  return (
    <>
      <EditTimeIntervals />
      <CreateGeofenceArea />
      <CreateDetectionArea />
      <CreateCorridor />
      <DeleteCorridor />
      <CreateApproach />
      <DeleteApproach />
      <EditBusStop />
      <DeleteBusStop />
      <CreateIntersection />
      <DeleteIntersection />
      <CreateSegment />
      <DeleteSegment />
    </>
  );
};
