import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterCamera, RunJobs, Page } from 'components';
import {
  deregisterCamera,
  fetchCameras,
  selectCameras,
  selectLoadingDeregisterCamera,
  setLoadingDeregisterCamera,
  selectLoadingFetchCameras,
} from 'features';
import {
  Avatar,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Button,
} from '@material-ui/core';
import { Videocam, Eject, Storage } from '@material-ui/icons';

export const CamerasList: React.FC = () => {
  const dispatch = useDispatch();
  const [selectedCameraId, setSelectedCameraId] = useState<string>('');
  const [actionType, setActionType] = useState<string>('');
  const loadingDeregisterCamera = useSelector(selectLoadingDeregisterCamera);
  const cameras = useSelector(selectCameras);
  const loadingFetchCameras = useSelector(selectLoadingFetchCameras);

  const handleDeregisterSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    dispatch(
      deregisterCamera({
        cameraId: selectedCameraId,
        date: event.currentTarget.date.value,
        reason: event.currentTarget.reason.value,
      }),
    );
    event.preventDefault();
  };

  useEffect(() => {
    if (loadingFetchCameras === 'idle') {
      dispatch(fetchCameras());
    }
  }, []);

  useEffect(() => {
    if (loadingDeregisterCamera === 'loaded') {
      dispatch(fetchCameras());
      dispatch(setLoadingDeregisterCamera('idle'));
      setSelectedCameraId('');
      setActionType('');
    }
  }, [loadingDeregisterCamera]);

  return (
    <Page title="Cameras" headerContent={<RegisterCamera />}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>ID</TableCell>
              <TableCell>Vehicle</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Registration date</TableCell>
              <TableCell>Deregistration date</TableCell>
              <TableCell>Deregistration reason</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {cameras.map(
              ({ cameraId, vehicle, type, registrationDate, deregistrationDate, deregistrationReason }, index) => (
                <TableRow key={index.toString()}>
                  <TableCell>
                    <Avatar>
                      <Videocam />
                    </Avatar>
                  </TableCell>
                  <TableCell>{cameraId}</TableCell>
                  <TableCell>{vehicle}</TableCell>
                  <TableCell>{type}</TableCell>
                  <TableCell>{registrationDate}</TableCell>
                  <TableCell>{deregistrationDate}</TableCell>
                  <TableCell>{deregistrationReason}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="Deregister" aria-label="deregister" placement="top">
                      <IconButton
                        edge="end"
                        onClick={() => {
                          setSelectedCameraId(cameraId);
                          setActionType('deregister');
                        }}
                      >
                        <Eject />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Run jobs" aria-label="run-jobs" placement="top">
                      <IconButton
                        edge="end"
                        onClick={() => {
                          setSelectedCameraId(cameraId);
                          setActionType('run-jobs');
                        }}
                      >
                        <Storage />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <RunJobs open={actionType === 'run-jobs'} setActionType={setActionType} cameraId={selectedCameraId} />
      <Dialog open={actionType === 'deregister'} onClose={() => setActionType('')}>
        <DialogTitle>Deregister camera</DialogTitle>
        <form onSubmit={handleDeregisterSubmit}>
          <DialogContent>
            <div>
              <TextField label="Reason" name="reason" required autoFocus />
            </div>
            <div>
              <TextField
                label="Date"
                name="date"
                type="date"
                defaultValue={new Date().toISOString().split('T')[0]}
                required
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setActionType('')}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">
              Deregister
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Page>
  );
};
