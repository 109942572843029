import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Page, ScheduleDialog } from 'components';
import {
  fetchSchedules,
  selectLoadingCreateSchedule,
  setLoadingCreateSchedule,
  selectLoadingUpdateSchedule,
  setLoadingUpdateSchedule,
  selectSchedules,
  handleDeleteSchedule,
  selectLoadingDeleteSchedule,
  resetLoadingDeleteSchedule,
  selectLoadingFetchSchedules,
} from 'features';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Tooltip,
  IconButton,
  Button,
} from '@material-ui/core';
import { Schedule, Edit, Delete } from '@material-ui/icons';

export const SchedulesList: React.FC = () => {
  const dispatch = useDispatch();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editJobId, setEditJobId] = useState('');
  const [deleteJobId, setDeleteJobId] = useState<string | null>(null);
  const schedules = useSelector(selectSchedules);
  const loadingFetchSchedules = useSelector(selectLoadingFetchSchedules);
  const loadingCreateSchedule = useSelector(selectLoadingCreateSchedule);
  const loadingUpdateSchedule = useSelector(selectLoadingUpdateSchedule);
  const loadingDeleteSchedule = useSelector(selectLoadingDeleteSchedule);

  const openCreateDialog = () => setIsCreateDialogOpen(true);

  useEffect(() => {
    if (loadingFetchSchedules === 'idle') {
      dispatch(fetchSchedules());
    }
  }, []);

  const onEditClick = (jobId: string) => {
    setEditJobId(jobId);
    setIsEditDialogOpen(true);
  };

  const onDeleteClick = async () => {
    if (deleteJobId) {
      dispatch(handleDeleteSchedule(deleteJobId));
    }
  };

  useEffect(() => {
    if (loadingDeleteSchedule === 'loaded') {
      dispatch(resetLoadingDeleteSchedule());
      setDeleteJobId(null);
      dispatch(fetchSchedules());
    }
  }, [loadingDeleteSchedule]);

  useEffect(() => {
    if (loadingCreateSchedule === 'loaded') {
      dispatch(fetchSchedules());
      dispatch(setLoadingCreateSchedule('idle'));
    }
  }, [loadingCreateSchedule]);

  useEffect(() => {
    if (loadingUpdateSchedule === 'loaded') {
      dispatch(fetchSchedules());
      dispatch(setLoadingUpdateSchedule('idle'));
    }
  }, [loadingUpdateSchedule]);

  return (
    <Page
      title="Schedules"
      headerContent={
        <Button onClick={openCreateDialog} variant="outlined">
          Create schedule
        </Button>
      }
    >
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width="60" />
              <TableCell>ID</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.map((id) => (
              <TableRow key={id}>
                <TableCell>
                  <Avatar>
                    <Schedule />
                  </Avatar>
                </TableCell>
                <TableCell>{id}</TableCell>
                <TableCell align="right">
                  <Tooltip title="Edit" aria-label="edit" placement="top">
                    <IconButton edge="end" onClick={() => onEditClick(id)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" aria-label="edit" placement="top">
                    <IconButton edge="end" onClick={() => setDeleteJobId(id)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ScheduleDialog
        isDialogOpen={isCreateDialogOpen}
        setIsDialogOpen={setIsCreateDialogOpen}
        title="Create Schedule"
      />

      <ScheduleDialog
        isDialogOpen={isEditDialogOpen}
        setIsDialogOpen={setIsEditDialogOpen}
        title="Edit Schedule"
        job={{ id: editJobId }}
        isEdit
      />

      <Dialog open={!!deleteJobId} onClose={() => setDeleteJobId(null)}>
        <DialogContent>{`Do you want to delete scheduler with id "${deleteJobId}"?`}</DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteJobId(null)}>No</Button>
          <Button onClick={onDeleteClick} type="button" variant="contained" color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};
