import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';
import { DialogName } from 'enums';
import { selectSelectedApproaches, handleCreateCorridor } from 'features';
import React from 'react';
import { MainDialog } from 'components/EditGrid/components/Dialogs/MainDialog';

export const CreateCorridor = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const approachIds = useSelector(selectSelectedApproaches);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const onSubmit = ({ currentTarget }: React.FormEvent<HTMLFormElement>) =>
    dispatch(
      handleCreateCorridor({
        approachIds,
        name: currentTarget.corridorName.value,
        axlPrefix: currentTarget.axlPrefix.value,
      }),
    );

  /* --------------------------------- Effects -------------------------------- */

  return (
    <MainDialog name={DialogName.CREATE_CORRIDOR} onSubmit={onSubmit}>
      <TextField
        label="Approach Ids"
        defaultValue={approachIds?.join(', ')}
        inputProps={{ readOnly: true }}
        multiline
        required
      />
      <TextField name="corridorName" label="Name" required />
      <TextField name="axlPrefix" label="axlPrefix" required />
    </MainDialog>
  );
};
