import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import {
  fetchCorridors,
  selectLoadingCorridors,
  resetLoadingCorridors,
  selectCorridors,
  setContextSelectedCorridor,
  selectLoadingDeleteCorridor,
  resetLoadingDeleteCorridor,
  selectLoadingCreateCorridor,
  resetLoadingCreateCorridor,
} from 'features';
import { CorridorsSliceState } from 'interfaces';
import { MapUtils } from 'components/EditGrid/utils';
import { LayerName, GroupName } from 'components/EditGrid/types';

export const useCorridors = (map: MapUtils | undefined) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const loadingCorridors = useSelector(selectLoadingCorridors);
  const loadingDeleteCorridor = useSelector(selectLoadingDeleteCorridor);
  const loadingCreateCorridor = useSelector(selectLoadingCreateCorridor);
  const corridors = useSelector(selectCorridors);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const handleCorridorsLoaded = () => {
    if (!map) return;
    const layers: VectorLayer[] = [];
    corridors.forEach(({ name, id, geojson }: CorridorsSliceState['entries'][0]) => {
      if (name && id) {
        layers.push(map.createLayer({ name: name || `Corridor ${id}`, as: LayerName.CORRIDOR, geojson }));
      }
    });
    map.setGroup({ name: GroupName.CORRIDORS, layers });
  };

  const setLayersVisible = () => {
    map?.getLayers({ group: GroupName.CORRIDORS }).forEach((layer) => layer.setVisible(true));
  };

  const dispatchContextClickData = (layer: VectorLayer, feature: Feature) => {
    if (layer?.get('group') === GroupName.CORRIDORS && feature) {
      const id = feature.get('corridorId');
      dispatch(setContextSelectedCorridor(id));
    } else {
      dispatch(setContextSelectedCorridor(null));
    }
  };

  const toggleSelectAllLayerFeatures = (layerName: string) => () => {
    const selection = map?.selectedFeatures[layerName];
    if (selection?.getLength()) {
      const allFeatures = (map?.getLayer(layerName) as VectorLayer).getSource().getFeatures();
      selection?.clear();
      selection?.extend(allFeatures);
    }
  };

  const initSelectionEvents = () => {
    map?.getLayers({ group: GroupName.CORRIDORS }).forEach((layer) => {
      const layerName = layer.get('name');
      map.selectedFeatures[layerName].on('change', toggleSelectAllLayerFeatures(layerName));
    });
  };

  const initEvents = () => {
    map?.on('contextmenu', dispatchContextClickData);
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (map) {
      dispatch(fetchCorridors());
      initEvents();
    }
  }, [map]);

  useEffect(() => {
    if (loadingCorridors === 'loaded') {
      dispatch(resetLoadingCorridors());
      handleCorridorsLoaded();
      initSelectionEvents();
    }
  }, [loadingCorridors]);

  useEffect(() => {
    if (loadingCreateCorridor === 'loaded') {
      dispatch(resetLoadingCreateCorridor());
      dispatch(fetchCorridors());
      setLayersVisible();
    }
  }, [loadingCreateCorridor]);

  useEffect(() => {
    if (loadingDeleteCorridor === 'loaded') {
      dispatch(resetLoadingDeleteCorridor());
      dispatch(fetchCorridors());
    }
  }, [loadingDeleteCorridor]);
};
