import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectCurrentTenant, selectNavigationIsOpen, setNavigationIsOpen } from 'features';
import { Button } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { LOG_IN_URL, LOG_OUT_URL } from 'consts';
import styles from './Header.module.scss';

export const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigationIsOpen = useSelector(selectNavigationIsOpen);
  const currentTenant = useSelector(selectCurrentTenant);

  const handleLoginClick = () => {
    window.location.assign(LOG_IN_URL);
  };

  const handleLogOutClick = () => {
    window.location.assign(LOG_OUT_URL);
  };

  const toggleDrawer = () => {
    dispatch(setNavigationIsOpen(!navigationIsOpen));
  };

  useEffect(() => {
    if (currentTenant && location.pathname === '/') {
      dispatch(setNavigationIsOpen(true));
    }
  }, [currentTenant, location.pathname]);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.itemsLeft}>
          <Button onClick={toggleDrawer} className={styles.navButton}>
            <MenuIcon />
          </Button>
        </div>
        <div className={styles.itemsRight}>
          <Button onClick={handleLoginClick} variant="outlined">
            LOG IN
          </Button>
          <Button onClick={handleLogOutClick} variant="outlined">
            LOG OUT
          </Button>
        </div>
      </div>
    </>
  );
};
