import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import deepmerge from 'deepmerge';
import { resetEditGridSlice, selectLoadingUploadBackup, resetLoadingUploadBackup } from 'features';
import {
  useGeofenceArea,
  useDetectionArea,
  useApproaches,
  useSegments,
  useBusStops,
  useOsmEdges,
  useOsmNodes,
  useCorridors,
  useTimeIntervals,
  useIntersections,
  useDirections,
} from 'components/EditGrid/hooks';
import { MapUtils, styleFunctions } from 'components/EditGrid/utils';
import { layersOptions as layers } from 'components/EditGrid/consts';

export const useMap = () => {
  /* ---------------------------------- State --------------------------------- */
  const [map, setMap] = useState<MapUtils | undefined>();
  const loadingUploadBackup = useSelector(selectLoadingUploadBackup);

  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();
  const geofenceArea = useGeofenceArea(map);
  const detectionArea = useDetectionArea(map);
  useIntersections(map);
  useApproaches(map);
  useSegments(map);
  useBusStops(map);
  useOsmEdges(map);
  useOsmNodes(map);
  useCorridors(map);
  useTimeIntervals(map);
  useDirections(map);

  /* -------------------------------- Selectors ------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const initMap = () => {
    const options = deepmerge({ layers, styleFunctions }, {});
    setMap(new MapUtils(options));
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    initMap();
    return () => {
      dispatch(resetEditGridSlice());
    };
  }, []);

  useEffect(() => {
    if (loadingUploadBackup === 'loaded') {
      dispatch(resetLoadingUploadBackup());
      initMap();
    }
  }, [loadingUploadBackup]);

  return {
    map,
    geofenceArea,
    detectionArea,
  };
};
