import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ApproachesResponse, ApproachesSliceState } from 'interfaces';
import { fetchApproaches, handleCreateApproach, handleDeleteApproach } from './thunks';

const initialState: ApproachesSliceState = {
  geojson: {
    type: 'FeatureCollection',
    features: [],
  },
  loading: 'idle',
  loadingCreate: 'idle',
  loadingDelete: 'idle',
};

export const approachesSlice = createSlice({
  initialState,
  name: 'approaches',
  reducers: {
    resetLoadingApproaches: (state) => {
      state.loading = 'idle';
    },
    resetLoadingCreateApproach: (state) => {
      state.loadingCreate = 'idle';
    },
    resetLoadingDeleteApproach: (state) => {
      state.loadingDelete = 'idle';
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchApproaches.fulfilled, (state, action: PayloadAction<ApproachesResponse>) => {
      state.geojson = action.payload;
      state.loading = 'loaded';
    });
    addCase(handleCreateApproach.fulfilled, (state) => {
      state.loadingCreate = 'loaded';
    });
    addCase(handleDeleteApproach.fulfilled, (state) => {
      state.loadingDelete = 'loaded';
    });
  },
});

export const selectApproaches = ({ approaches }: RootState) => approaches.geojson;
export const selectLoadingApproaches = ({ approaches }: RootState) => approaches.loading;
export const selectLoadingCreateApproach = ({ approaches }: RootState) => approaches.loadingCreate;
export const selectLoadingDeleteApproach = ({ approaches }: RootState) => approaches.loadingDelete;

export const {
  resetLoadingApproaches,
  resetLoadingCreateApproach,
  resetLoadingDeleteApproach,
} = approachesSlice.actions;
