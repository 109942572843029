import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { fetchDirections } from 'features';
import { DirectionsResponse, DirectionsSliceState } from 'interfaces';

const initialState: DirectionsSliceState = {
  entries: [],
  loading: 'idle',
};

export const directionsSlice = createSlice({
  initialState,
  name: 'directions',
  reducers: {
    resetLoadingDirections: (state) => {
      state.loading = 'idle';
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchDirections.fulfilled, (state, action: PayloadAction<DirectionsResponse>) => {
      state.entries = action.payload;
      state.loading = 'loaded';
    });
  },
});

export const selectDirections = ({ directions }: RootState) => directions.entries;
export const selectLoadingDirections = ({ directions }: RootState) => directions.loading;

export const { resetLoadingDirections } = directionsSlice.actions;
