import 'ol/ol.css';
import { LayersMenu, FabMenu, ContextMenu, Dialogs, Tooltip } from './components';
import { useMap } from './hooks/useMap';
import styles from './EditGrid.module.scss';

export const EditGrid = () => {
  /* ---------------------------------- State --------------------------------- */

  /* ---------------------------------- Hooks --------------------------------- */
  const { map, geofenceArea, detectionArea } = useMap();

  /* -------------------------------- Selectors ------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */

  /* --------------------------------- Effects -------------------------------- */

  return (
    <div className={styles.root}>
      <div id="olmap" className={styles.map} />
      {map && (
        <>
          <LayersMenu map={map} className={styles.layersMenu} />
          <ContextMenu map={map} geofenceArea={geofenceArea} detectionArea={detectionArea} />
          <Tooltip map={map} />
          <FabMenu className={styles.fabMenu} />
          <Dialogs />
        </>
      )}
    </div>
  );
};
