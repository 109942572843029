import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { runJobs } from 'features';
import { RunJobsProps } from 'interfaces';
import styles from './RunJobs.module.scss';

export const RunJobs: React.FC<RunJobsProps> = ({ open, setActionType, cameraId }) => {
  const dispatch = useDispatch();
  const [jobType, setJobType] = useState('VD');
  const [selectedStartDate, setSelectedStartDate] = useState<MaterialUiPickersDate>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<MaterialUiPickersDate>(new Date());

  const closeDialog = () => {
    setActionType('');
    setSelectedStartDate(new Date());
    setSelectedEndDate(new Date());
    setJobType('VD');
  };

  const submitRunJobs = async (event: any) => {
    event.preventDefault();

    const startDate = event.target[1]?.value;
    const endDate = event.target[3]?.value;

    dispatch(
      runJobs({
        type: jobType,
        cameraId,
        startDate,
        endDate,
      }),
    );
    closeDialog();
  };

  return (
    <>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>
          Run Jobs for camera:
          <b>
            <i>{` ${cameraId}`}</i>
          </b>
        </DialogTitle>
        <form onSubmit={submitRunJobs}>
          <DialogContent className={styles.dialogContent}>
            <FormControl>
              <InputLabel>Job type</InputLabel>
              <Select
                value={jobType}
                onChange={(e: ChangeEvent<{ value: unknown }>) => {
                  setJobType(e.target.value as string);
                }}
              >
                <MenuItem value="VD">VD</MenuItem>
                <MenuItem value="COUNTING">Counting</MenuItem>
              </Select>
            </FormControl>

            <div className={styles.datePickersContainer}>
              <div>
                <p className={styles.labelStartEnd}>Start date</p>
                <KeyboardDatePicker
                  key="start-date"
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  value={selectedStartDate}
                  onChange={(date: MaterialUiPickersDate) => setSelectedStartDate(date)}
                  autoOk
                />
              </div>
              <div>
                <p className={styles.labelStartEnd}>End date</p>
                <KeyboardDatePicker
                  key="end-date"
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  value={selectedEndDate}
                  onChange={(date: MaterialUiPickersDate) => setSelectedEndDate(date)}
                  autoOk
                />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button type="submit">Run Jobs</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
