import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDirections, selectLoadingDirections, resetLoadingDirections } from 'features';
import { MapUtils } from 'components/EditGrid/utils';

export const useDirections = (map: MapUtils | undefined) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const loadingDirections = useSelector(selectLoadingDirections);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */

  /* --------------------------------- Effects -------------------------------- */

  useEffect(() => {
    if (map) {
      dispatch(fetchDirections());
    }
  }, [map]);

  useEffect(() => {
    if (loadingDirections === 'loaded') {
      dispatch(resetLoadingDirections());
    }
  }, [loadingDirections]);
};
