import { Feature } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { Stroke, Style, Circle, Fill, Text, Icon } from 'ol/style';
import { StyleFunction } from 'ol/style/Style';
import { LineString } from 'ol/geom';
import { Coordinate } from 'ol/coordinate';
import { ColorLike } from 'ol/colorlike';
import BusStopIcon from 'assets/images/Bus stop@3x.svg';
import { LayerName } from 'components/EditGrid/types';

const styleCache: { [key: string]: { [key: string]: Style } } = {
  intersections: {},
  osmnodes: {},
};

const setUpTwoWayDirections = (
  feature: FeatureLike,
  width: number,
  resolutionWidth: number,
  resolution: number,
  colors: Array<ColorLike>,
  isDotted = false,
) => {
  const styles: Style[] = [];

  colors.forEach((color, index) => {
    const dist = resolutionWidth * resolution * (index - (colors.length - 1) / 2);
    const geom = feature.getGeometry() as LineString;
    const coords: Coordinate[] = [];

    geom.forEachSegment((from, to) => {
      const angle = Math.atan2(to[1] - from[1], to[0] - from[0]);
      const newFrom = [Math.sin(angle) * dist + from[0], -Math.cos(angle) * dist + from[1]];
      const newTo = [Math.sin(angle) * dist + to[0], -Math.cos(angle) * dist + to[1]];

      coords.push(newFrom);
      coords.push(newTo);
    });

    styles.push(
      new Style({
        geometry: new LineString(coords),
        stroke: new Stroke({
          color,
          width,
          lineDash: isDotted ? [1, 7] : undefined,
        }),
      }),
    );
  });

  return styles;
};

const createCircle = (color: string, sizeMultiplier: number, resolution: number) => {
  let radius = 4 * sizeMultiplier;

  if (resolution <= 1) radius = 12 * sizeMultiplier;
  else if (resolution <= 3) radius = 9 * sizeMultiplier;
  else if (resolution <= 5) radius = 7 * sizeMultiplier;
  else if (resolution <= 7) radius = 5 * sizeMultiplier;

  return new Style({
    image: new Circle({
      radius,
      fill: new Fill({ color }),
      stroke: new Stroke({ color: '#fff', width: 2 }),
    }),
  });
};

const createClusterCircle = (
  text: string,
  circleFillColor: string,
  circleBorderColor: string,
  textColor: string,
  isSelected = false,
): Style =>
  new Style({
    image: new Circle({
      radius: isSelected ? 14 : 9,
      stroke: new Stroke({ color: circleBorderColor }),
      fill: new Fill({ color: circleFillColor }),
    }),
    text: new Text({
      text,
      fill: new Fill({ color: textColor }),
    }),
  });

const lineActive = (feature: FeatureLike, resolution: number) => {
  const resolutionWidth = 3;
  const width = 2;
  return [
    ...setUpTwoWayDirections(feature, width + 3, resolutionWidth, resolution, ['transparent', 'transparent', 'black']),
    ...setUpTwoWayDirections(feature, width, resolutionWidth, resolution, ['transparent', 'transparent', '#0079FF']),
  ];
};

const pointActive = (_: FeatureLike, resolution: number) => createCircle('#30CB6A', 1, resolution);

export const styleFunctions: { [layerName: string]: StyleFunction } = {
  selectedFeature: (feature, resolution) => {
    if (feature.getGeometry()?.getType() === 'LineString') {
      return lineActive(feature, resolution);
    }
    return pointActive(feature, resolution);
  },
  [LayerName.INTERSECTIONS]: (feature) => {
    const cluster = feature.get('features');
    const text = String(cluster?.length || '');
    const isSelected = cluster ? cluster.find((f: Feature) => f.get('isSelected')) : feature.get('isSelected');
    const isHovered = feature.get('isHovered');

    const createIntersectionCluster = (selected = false): Style =>
      createClusterCircle(text, '#0079FF', '#FFF', '#FFF', selected);

    let cachedStyle = styleCache.intersections[text];

    if (!cachedStyle) {
      cachedStyle = createIntersectionCluster();
      styleCache.intersections[text] = cachedStyle;
    }

    return isSelected || isHovered ? createIntersectionCluster(true) : cachedStyle;
  },

  [LayerName.APPROACHES]: (feature, resolution) => {
    const colors = ['transparent', 'transparent', '#0079FF'];
    const resolutionWidth = 3;
    const width = 2;

    return setUpTwoWayDirections(feature, width, resolutionWidth, resolution, colors);
  },

  [LayerName.SEGMENTS]: (feature, resolution) => {
    if (resolution < 1) {
      return setUpTwoWayDirections(feature, 2, 3, resolution, ['transparent', 'transparent', '#30CB6A']);
    }

    return [
      new Style({
        stroke: new Stroke({
          color: '#30CB6A',
          width: 2,
        }),
      }),
    ];
  },

  [LayerName.BUS_STOPS]: (_, resolution) => {
    let scale = 0.5;

    if (resolution < 10) {
      scale = 0.7;
    }
    if (resolution < 7) {
      scale = 0.6;
    }
    if (resolution < 5) {
      scale = 0.8;
    }
    if (resolution < 2) {
      scale = 1;
    }

    return new Style({
      image: new Icon({
        src: BusStopIcon,
        anchor: [0.5, 0.5],
        rotateWithView: true,
        scale,
      }),
    });
  },

  [`${LayerName.BUS_STOPS}Selected`]: (_, resolution) => {
    let scale = 0.9;

    if (resolution < 10) {
      scale = 1.1;
    }
    if (resolution < 7) {
      scale = 1;
    }
    if (resolution < 5) {
      scale = 1.2;
    }
    if (resolution < 2) {
      scale = 1.4;
    }

    return new Style({
      image: new Icon({
        src: BusStopIcon,
        anchor: [0.5, 0.5],
        rotateWithView: true,
        scale,
      }),
    });
  },

  [LayerName.OSM_EDGES]: (feature, resolution) => {
    if (resolution < 1) {
      return setUpTwoWayDirections(feature, 2, 3, resolution, ['transparent', 'transparent', '#1F2041']);
    }

    return [
      new Style({
        stroke: new Stroke({
          color: '#1F2041',
          width: 1,
        }),
      }),
    ];
  },

  [LayerName.OSM_NODES]: (feature) => {
    const cluster = feature.get('features');
    const text = String(cluster?.length || '');
    let style = styleCache.osmnodes[text];
    if (!style) {
      style = createClusterCircle(text, '#1F2041', '#FFF', '#FFF');
      styleCache.osmnodes[text] = style;
    }

    return style;
  },

  [LayerName.CORRIDOR]: (feature, resolution) =>
    setUpTwoWayDirections(feature, 3, 3, resolution, ['transparent', 'transparent', '#FF00FF']),

  [`${LayerName.CORRIDOR}Selected`]: (feature, resolution) =>
    setUpTwoWayDirections(feature, 3, 3, resolution, ['transparent', 'transparent', '#0079FF']),

  [LayerName.GEOFENCE_AREA]: () => {
    return new Style({
      stroke: new Stroke({
        color: '#0079FF',
        width: 3,
        lineDash: [5, 10],
      }),
      fill: new Fill({
        color: 'rgba(0, 121, 255, 0.1)',
      }),
    });
  },

  [LayerName.SELECTED_POINT]: (_, resolution) => createCircle('#808080', 0.5, resolution),
};
