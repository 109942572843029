import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { DialogName } from 'enums';
import {
  selectSelectedEdgesCoords,
  selectSelectedEdgesMaxSpeed,
  selectDirections,
  selectStartEndIntersections,
  handleCreateSegment,
} from 'features';
import { MainDialog } from 'components/EditGrid/components/Dialogs/MainDialog';

export const CreateSegment = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const edges = useSelector(selectSelectedEdgesCoords);
  const maxSpeed = useSelector(selectSelectedEdgesMaxSpeed);
  const directions = useSelector(selectDirections);
  const [fromId, toId] = useSelector(selectStartEndIntersections) || [];

  /* ---------------------------------- State --------------------------------- */
  const [directionId, setDirectionId] = useState('');

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const onSubmit = ({ currentTarget }: React.FormEvent<HTMLFormElement>) => {
    dispatch(
      handleCreateSegment({
        edges,
        directionId,
        speed: currentTarget.maxSpeed.value,
        fromIntersectionId: currentTarget.fromId.value,
        toIntersectionId: currentTarget.toId.value,
      }),
    );
  };

  const onDirectionChange = (direction: string) => {
    setDirectionId(direction);
  };

  /* --------------------------------- Effects -------------------------------- */

  return (
    <MainDialog name={DialogName.CREATE_SEGMENT} onSubmit={onSubmit}>
      <TextField name="fromId" defaultValue={fromId} label="Start intersection ID" required />
      <TextField name="toId" defaultValue={toId} label="End intersection ID" required />
      <TextField name="maxSpeed" defaultValue={maxSpeed} type="number" label="Speed (km/h)" required />
      <FormControl>
        <InputLabel>Direction</InputLabel>
        <Select
          value={directionId}
          onChange={(e: React.ChangeEvent<{ value: unknown }>) => onDirectionChange(String(e.target.value))}
          required
        >
          {directions.map(({ name, id }) => (
            <MenuItem value={id} key={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </MainDialog>
  );
};
