export enum LayerName {
  GEOFENCE_AREA = 'Geofence area',
  GEOFENCE_AREA_DRAWING = 'Drawing geofence area',
  DETECTION_AREA = 'Detection area',
  DETECTION_AREA_DRAWING = 'Drawing detection area',
  SELECTED_POINT = 'Selected point',
  INTERSECTIONS = 'Intersections',
  CORRIDOR = 'Corridor',
  APPROACHES = 'Approaches',
  SEGMENTS = 'Segments',
  BUS_STOPS = 'Bus stops',
  OSM_NODES = 'OSM nodes',
  OSM_EDGES = 'OSM edges',
}

export enum GroupName {
  CORRIDORS = 'Corridors',
}
