export enum TenantId {
  AXILION_DEV = 'ce6a8964-5bcc-4ed2-88f5-04034f04c923',
  AXILION_CSP = '20daceeb-7a05-47db-944e-4bb42cff60f7',
  AXILION_TEST = 'a0ef4bae-3886-4687-a672-03b9a93d5d87',
  AXILION_QA = '44c25cb8-ff50-405c-9368-6f5304e02a7b',
  BOSTON = '9afd522f-56aa-4b54-8a26-cc56773d60fb',
  TEL_AVIV = 'aa640f10-95f8-4f05-96f1-529dbbc11897',
  NEW_YORK = 'c464fb65-7f37-4274-84bf-0ccc8f4d937f',
  DUBAI = '95a57a75-4d87-4299-9f16-1f9c5a2c98e7',
  JERUSALEM = '4c15c98f-5e6f-4b9c-abe0-8590ed61dc5a',
  BOLZANO_STAGE = 'b6154596-da83-4670-948f-6b39a948e9a1',
  BOLZANO_CLIENT = '3c673c8b-29d8-4c42-929f-a346a66a881f',
}
