import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectToastMessage, setToastMessage } from 'features';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './ToastMessage.module.scss';

export const ToastMessage: React.FC = () => {
  const message = useSelector(selectToastMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!message) return;
    toast.error(message);
    dispatch(setToastMessage(''));
  }, [message]);

  return (
    <ToastContainer
      position="bottom-center"
      hideProgressBar
      autoClose={3000}
      className={styles.container}
      transition={Slide}
    />
  );
};
