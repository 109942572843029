import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { AppSliceState } from 'interfaces';
import { DialogName } from 'enums';

const initialState: AppSliceState = {
  navigationIsOpen: false,
  activeDialog: null,
};

export const appSlice = createSlice({
  initialState,
  name: 'app',
  reducers: {
    setNavigationIsOpen: (state, action: PayloadAction<boolean>) => {
      state.navigationIsOpen = action.payload;
    },
    setActiveDialog: (state, action: PayloadAction<DialogName | null>) => {
      state.activeDialog = action.payload;
    },
  },
});

export const selectNavigationIsOpen = ({ app }: RootState): boolean => app.navigationIsOpen;
export const selectActiveDialog = ({ app }: RootState): AppSliceState['activeDialog'] => app.activeDialog;

export const { setNavigationIsOpen, setActiveDialog } = appSlice.actions;
