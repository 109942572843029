import axios from 'axios';
import { API_BASE_URL } from 'consts';
import { interceptRequests } from 'utils';

const config = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
};

export const api = axios.create({
  baseURL: API_BASE_URL,
  ...config,
});

interceptRequests(api);
