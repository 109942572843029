import { GeoJSON } from 'geojson';
import { Map as OlMap, View, Feature, MapBrowserEvent } from 'ol';
import BaseLayer from 'ol/layer/Base';
import Layer from 'ol/layer/Layer';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Collection from 'ol/Collection';
import { XYZ, Cluster } from 'ol/source';
import * as olExtent from 'ol/extent';
import { Polygon } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { GeoJSON as OlGeoJSON } from 'ol/format';
import { defaults as defaultControls } from 'ol/control';
import { shiftKeyOnly, singleClick } from 'ol/events/condition';
import { defaults as defaultInteractions, Select, DragBox } from 'ol/interaction';
import Style, { createEditingStyle } from 'ol/style/Style';
import { FeatureLike } from 'ol/Feature';
import { SelectEvent } from 'ol/interaction/Select';
import {
  MapUtilsOptions,
  OlMapOptions,
  TilesOptions,
  LayerOptions,
  AllLayersOptions,
  LayerInitOptions,
  GetLayerOptions,
  FitExtentOptions,
  MapEventsCallbacks,
  SelectedFeatures,
  LastHoveredFeature,
  LayersWithHoverCursor,
} from './types';
import {
  defaultLayersOptions,
  defaultTilesOptions,
  defaultViewOptions,
  defaultControlsOptions,
  defaultInteractionsOptions,
  centerOnLayerOptions,
  centerOnFeaturesOptions,
  MAIN_GROUP_NAME,
  DEFAULT_TILE_NAME,
  DEFAULT_TARGET_ID,
  olProp,
} from './consts';

export class MapUtils {
  olMap;

  mainGroup;

  tileLayer;

  styleFunctions;

  layersOptions: AllLayersOptions;

  tilesOptions: TilesOptions;

  layersWithHoverCursor: LayersWithHoverCursor = [];

  MapEventsCallbacks: MapEventsCallbacks = { click: [], pointermove: [], contextmenu: [] };

  selectedFeatures: SelectedFeatures = {};

  lastHoveredFeature: LastHoveredFeature = null;

  constructor(options: MapUtilsOptions) {
    this.styleFunctions = options.styleFunctions;
    this.tilesOptions = { ...defaultTilesOptions, ...options.tiles };
    this.layersOptions = MapUtils.initLayersOptions(options.layers);
    const { olMap, tileLayer, mainGroup } = this.initOlMap(options);
    this.olMap = olMap;
    this.tileLayer = tileLayer;
    this.mainGroup = mainGroup;
    this.initEvents();
    this.initSelection();
  }

  initOlMap(options: OlMapOptions) {
    const targetId = DEFAULT_TARGET_ID;
    const target = document.getElementById(targetId);
    if (target) target.innerHTML = '';
    else throw new Error(`Open Layers target element "#${targetId}" is not in document.`);
    const { coords, zoom, maxZoom } = { ...defaultViewOptions, ...options.view };
    const { url, maxZoom: tilesMaxZoom } = this.tilesOptions[DEFAULT_TILE_NAME];
    const view = new View({ center: fromLonLat(coords), zoom, maxZoom });
    const tileSource = new XYZ({ url, maxZoom: tilesMaxZoom });
    const tileLayer = new TileLayer({ source: tileSource });
    const mainGroup = new LayerGroup();
    const layers = [tileLayer, mainGroup];
    const controls = defaultControls({ ...defaultControlsOptions, ...options.controls });
    const interactions = defaultInteractions({ ...defaultInteractionsOptions, ...options.interactions });
    const olMap = new OlMap({ view, layers, target, controls, interactions });
    mainGroup.set(olProp.LAYER_NAME, MAIN_GROUP_NAME);
    return { olMap, tileLayer, mainGroup };
  }

  set tile(nameOrSource: string | XYZ) {
    if (nameOrSource instanceof XYZ) {
      this.tileLayer.setSource(nameOrSource);
    } else {
      const { url, maxZoom } = this.tilesOptions[nameOrSource];
      this.tileLayer.setSource(new XYZ({ url, maxZoom }));
    }
  }

  get tileName() {
    const tileSource = this.tileLayer.getSource() as XYZ;
    return Object.entries(this.tilesOptions).find(([, options]) =>
      tileSource.getUrls()?.some((url: string) => url === options.url),
    )?.[0];
  }

  get rootLayers() {
    return this.getChildren(this.mainGroup);
  }

  getLayer(layerOrName: BaseLayer | string, options?: GetLayerOptions) {
    if (layerOrName === MAIN_GROUP_NAME) return this.mainGroup;
    if (typeof layerOrName === 'string') {
      return this.getLayersBy(olProp.LAYER_NAME, layerOrName, {
        findOne: true,
        ...options,
      })[0];
    }
    return layerOrName;
  }

  getLayersBy(prop: string, value: string, options?: GetLayerOptions) {
    return this.getLayers({
      condition: (layer: Layer) => layer.get(prop) === value,
      ...options,
    });
  }

  getChildren(groupOrString: BaseLayer | string) {
    return this.getLayers({
      group: this.getLayer(groupOrString) as LayerGroup,
      deepSearch: false,
    });
  }

  getLayers({
    group: groupOrName = this.mainGroup,
    condition = () => true,
    deepSearch = true,
    findOne,
  }: GetLayerOptions = {}) {
    const startNode = this.getLayer(groupOrName) as LayerGroup;
    if (!(startNode instanceof LayerGroup)) return [];
    const results = [] as BaseLayer[];
    const searchChildren = (currentNode: LayerGroup | OlMap) => {
      const children = currentNode.getLayers().getArray();
      let i = 0;
      do {
        const layer = children[i];
        if (!layer) break;
        const match = condition(layer);
        if (match) {
          results.push(layer);
          if (findOne) break;
        }
        if (deepSearch && layer instanceof LayerGroup) searchChildren(layer);
        i += 1;
      } while (i <= children.length);
    };
    searchChildren(startNode);
    return results;
  }

  setLayer(options: LayerInitOptions) {
    const { group, centerOnCreate } = this.setLayerOptions(options);
    let layer: VectorLayer;
    const olderLayer = this.getLayer(options.name, { group }) as VectorLayer;
    if (olderLayer && options.geojson) {
      layer = olderLayer;
      let source = layer.getSource();
      if (source instanceof Cluster) source = source.getSource();
      source.clear();
      source.addFeatures(MapUtils.createFeatures(options.geojson));
      MapUtils.increaseRevision(source);
    } else {
      layer = this.createLayer({ ...options, skipSetOptions: true });
      this.addLayerToGroup(layer, group);
      if (centerOnCreate) this.centerOnLayer(layer);
    }
    return layer;
  }

  setGroup(initData: { name: string; layers: BaseLayer[]; parentGroup?: LayerGroup | string }) {
    const { name, layers, parentGroup } = initData;
    let groupLayer;
    let collection;
    layers.forEach((l) => l.set(olProp.PARENT_GROUP, name));
    const olderGroupLayer = this.getLayer(name, { group: parentGroup }) as LayerGroup;
    if (olderGroupLayer) {
      collection = olderGroupLayer.getLayers();
      collection.clear();
      collection.extend(layers);
      groupLayer = olderGroupLayer;
    } else {
      groupLayer = new LayerGroup({ layers });
      groupLayer.set(olProp.LAYER_NAME, name);
      collection = groupLayer.getLayers();
      this.addLayerToGroup(groupLayer, parentGroup || this.mainGroup);
    }
    MapUtils.increaseRevision(collection);
    return groupLayer;
  }

  addLayerToGroup(layer: Layer | LayerGroup, groupOrString: LayerGroup | string) {
    const group = this.getLayer(groupOrString) as LayerGroup;
    group.getLayers().extend([layer]);
    layer.set(olProp.PARENT_GROUP, group.get(olProp.LAYER_NAME));
    return group;
  }

  removeLayer(layerOrName: BaseLayer | string) {
    const layer = this.getLayer(layerOrName);
    const parentGroupName = layer.get(olProp.PARENT_GROUP);
    const parentGroup = this.getLayer(parentGroupName) as LayerGroup;
    if (!layer) return;
    parentGroup.getLayers().remove(layer);
  }

  centerOnLayer(layerOrName: VectorLayer | string, options?: FitExtentOptions) {
    const layer = this.getLayer(layerOrName) as VectorLayer;
    const extent = layer.getSource().getExtent();
    if (!Number.isFinite(extent[0])) return;
    this.fitExtent(extent, { ...centerOnLayerOptions, ...options });
  }

  centerOnFeatures(features: Feature[], options?: FitExtentOptions) {
    const extent = olExtent.createEmpty();
    features.forEach((feature) => {
      const geometry = feature.getGeometry()?.getExtent();
      if (geometry) olExtent.extend(extent, geometry);
    });
    this.fitExtent(extent, { ...centerOnFeaturesOptions, ...options });
  }

  getRevision(target: VectorLayer | LayerGroup | string) {
    const layerOrGroup = this.getLayer(target) as VectorLayer;
    if (layerOrGroup instanceof LayerGroup) {
      return layerOrGroup.getLayers().get(olProp.REVISION);
    }
    return layerOrGroup.getSource().get(olProp.REVISION);
  }

  on(eventType: 'click' | 'pointermove' | 'contextmenu', fn: Function) {
    const callbacks = this.MapEventsCallbacks[eventType];
    if (!callbacks.includes(fn)) callbacks.push(fn);
  }

  useCursor(layerOrString: BaseLayer | string, pointerType = 'pointer') {
    let targetLayer: BaseLayer;
    this.on('pointermove', (layer: BaseLayer, feature: Feature) => {
      if (!targetLayer) {
        targetLayer = this.getLayer(layerOrString);
        this.layersWithHoverCursor.push(targetLayer);
      }
      if (layer === targetLayer && feature) {
        document.body.style.cursor = pointerType;
      } else if (!feature || !this.layersWithHoverCursor.includes(layer)) {
        document.body.style.cursor = 'default';
      }
    });
  }

  /* ------------------------------------------------------------------------------------ */
  static initLayersOptions(layersOptions: LayerOptions | undefined) {
    if (!layersOptions) return {};
    return Object.fromEntries(
      Object.entries(layersOptions).map(([layerName, options]) => [
        layerName,
        {
          ...defaultLayersOptions,
          ...options,
        },
      ]),
    );
  }

  setLayerOptions({ name, as, style, styleSelected, ...rest }: LayerOptions & Pick<LayerInitOptions, 'name' | 'as'>) {
    const asLyaer = as || name;
    const baseOptions = this.layersOptions[asLyaer];
    const options = {
      ...baseOptions,
      style: style || this.styleFunctions?.[asLyaer],
      styleSelected: styleSelected || this.styleFunctions?.[`${asLyaer}Selected`],
      ...rest,
    };
    this.layersOptions[name] = options;
    this.createSelect(name);
    return options;
  }

  createLayer({ geojson, skipSetOptions, ...options }: LayerInitOptions & { skipSetOptions?: boolean }) {
    const { style, zIndex, visible, cluster, distance } = skipSetOptions
      ? this.layersOptions[options.name]
      : this.setLayerOptions(options);
    const source = MapUtils.createSource({ geojson, cluster, distance });
    const layer = new VectorLayer({ source, style, zIndex, visible });
    layer.set(olProp.LAYER_NAME, options.name);
    return layer;
  }

  static createSource({ geojson, cluster, distance }: { geojson?: GeoJSON; cluster?: boolean; distance?: number }) {
    let features;
    if (geojson) features = MapUtils.createFeatures(geojson);
    let source = new VectorSource({ features });
    const canBeClustered = features?.every((feature) => feature.getGeometry()?.getType() === 'Point');
    if (features && cluster && canBeClustered) {
      source = new Cluster({ source, distance });
    }
    MapUtils.increaseRevision(source);
    return source;
  }

  static createFeatures(geojson: GeoJSON) {
    if (geojson.type === 'Polygon') {
      const geometry = new Polygon(geojson.coordinates);
      geometry.transform('EPSG:4326', 'EPSG:3857');
      const features = [];
      if (geojson.coordinates.length) {
        features.push(new Feature({ geometry }));
      }
      return features;
    }
    return new OlGeoJSON({ featureProjection: 'EPSG:3857' }).readFeatures(geojson);
  }

  static increaseRevision(target: VectorSource | Collection<BaseLayer>) {
    target.set(olProp.REVISION, (Number(target.get(olProp.REVISION)) || 0) + 1);
  }

  fitExtent(extent: olExtent.Extent, options?: FitExtentOptions) {
    if (!Number.isFinite(extent[0])) return;
    this.olMap.getView().fit(extent, { ...options });
  }

  initEvents() {
    this.initBrowserEvents();
    this.olMap.on('moveend', () => this.restyleSelectedClusters());
  }

  initBrowserEvents() {
    const eventTypes = ['click', 'contextmenu', 'pointermove'];
    const setHoverState = (layer: Layer | undefined, feature: FeatureLike | undefined, eventType: string) => {
      let hasHover = false;
      if (
        eventType === 'pointermove' &&
        layer &&
        feature instanceof Feature &&
        this.lastHoveredFeature?.feature !== feature &&
        this.layersOptions[layer.get('name')]?.hasHoverState
      ) {
        this.lastHoveredFeature = { layer, feature };
        feature.set('isHovered', true);
        hasHover = true;
      }
      if (!hasHover && this.lastHoveredFeature && this.lastHoveredFeature?.feature !== feature) {
        this.lastHoveredFeature?.feature.set('isHovered', false);
        this.lastHoveredFeature = null;
      }
    };
    const processEvent = (event: MapBrowserEvent, eventType: string) => {
      const callbacks = this.MapEventsCallbacks[eventType];
      if (eventType === 'pointermove' && event.dragging) return;
      const pixel = this.olMap.getEventPixel(event.originalEvent);
      const [feature, layer] = this.olMap.forEachFeatureAtPixel(pixel, (f, l) => [f, l]) || [undefined, undefined];
      setHoverState(layer, feature, eventType);
      callbacks.forEach((callback) => callback(layer, feature, event));
    };
    eventTypes.forEach((eventType) => this.olMap.on(eventType, (event) => processEvent(event, eventType)));
  }

  initSelection() {
    Object.keys(this.layersOptions).forEach((layerName) => this.createSelect(layerName));
    this.initDragBox();
  }

  createSelect(layerName: string) {
    if (!this.layersOptions[layerName].selectable || this.selectedFeatures[layerName]) return;
    const selection: Collection<Feature> = new Collection();
    this.selectedFeatures[layerName] = selection;
    this.useCursor(layerName, 'pointer');
    const select = new Select({
      layers: (layer: Layer) => layer.get('name') === layerName,
      features: selection,
      condition: singleClick,
      style: this.selectedFeatureStyleFunction(layerName),
    });
    select.on('select', (event: SelectEvent) => {
      const cluster = event.selected[0]?.get('features');
      const shiftPressed = (event.mapBrowserEvent.originalEvent as PointerEvent).shiftKey;
      if (cluster?.length > 1 && this.layersOptions[layerName].zoomOnClusterClick) {
        this.centerOnFeatures(cluster);
        this.selectedFeatures[layerName].clear();
      } else {
        selection.changed();
      }
      if (event.selected.length) {
        event.mapBrowserEvent.stopPropagation();
        Object.values(this.selectedFeatures).forEach((features: Collection<Feature>) => {
          if (!shiftPressed && features !== select.getFeatures()) {
            features.clear();
            features.changed();
          }
        });
      }
    });
    selection.on('remove', () => window.setTimeout(() => this.restyleSelectedClusters(true)));
    select.set('zIndex', this.layersOptions[layerName].zIndex);
    const interactions = this.olMap.getInteractions();
    const layerZIndex = this.layersOptions[layerName].zIndex;
    const insertPoint = interactions.getArray().findIndex((interaction) => interaction.get('zIndex') > layerZIndex);
    interactions.insertAt(insertPoint, select);
  }

  initDragBox() {
    const dragBox = new DragBox({ condition: shiftKeyOnly });
    const dragBoxEnabled = Object.values(this.layersOptions).some((opt) => opt.dragBox);
    if (dragBoxEnabled) {
      this.olMap.addInteraction(dragBox);
    }
    dragBox.on('boxend', () => {
      Object.entries(this.layersOptions || []).forEach(([layerName, options]) => {
        if (!options.selectable || !options.dragBox) return;
        const layer = this.getLayer(layerName) as VectorLayer;
        if (!layer || layer instanceof LayerGroup || !layer.getVisible()) return;
        const extent = dragBox.getGeometry().getExtent();
        const selection = this.selectedFeatures[layerName];
        const features: Feature[] = [];
        layer?.getSource().forEachFeatureIntersectingExtent(extent, (feature) => {
          if (!selection.getArray().includes(feature)) features.push(feature);
        });
        if (features.length) {
          selection.extend(features);
          selection.changed();
        }
      });
    });
  }

  restyleSelectedClusters(refreshSource = false) {
    if (!this.layersOptions) return;
    Object.keys(this.layersOptions).forEach((layerName) => {
      const { cluster } = this.layersOptions[layerName];
      const selectedFeatures = this.selectedFeatures[layerName];
      if (!cluster || !selectedFeatures) return;
      const layer = this.getLayer(layerName) as VectorLayer;
      if (!layer || layer instanceof LayerGroup) return;
      if (refreshSource) layer.getSource().refresh();
      const allFeatures = layer.getSource().getFeatures();
      selectedFeatures.forEach((selected) => {
        allFeatures.forEach((feature) => {
          if (selected.get('features').some((child: Feature) => feature.get('features').includes(child))) {
            feature.setStyle(this.selectedFeatureStyleFunction(layerName)(feature));
          }
        });
      });
    });
  }

  selectedFeatureStyleFunction(layerName: string) {
    const defaultResolution = 2;
    return (feature: FeatureLike, resolution = this.olMap.getView().getResolution() || defaultResolution) => {
      const { styleSelected } = this.layersOptions[layerName];
      const generalStyle = this.styleFunctions?.selectedFeature;
      const geometryType = feature.getGeometry()?.getType();
      if (styleSelected) return styleSelected(feature, resolution);
      if (generalStyle) return generalStyle(feature, resolution);
      if (geometryType) return createEditingStyle()[geometryType];
      return new Style();
    };
  }
}
