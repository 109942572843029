import { LayerOptions } from 'components/EditGrid/utils/mapUtils';
import { LayerName } from 'components/EditGrid/types';

export const layersOptions: { [key: string]: LayerOptions } = {
  [LayerName.GEOFENCE_AREA]: {
    visible: false,
    zIndex: 100,
  },
  [LayerName.SELECTED_POINT]: {
    zIndex: 95,
  },
  [LayerName.INTERSECTIONS]: {
    cluster: true,
    zIndex: 90,
    selectable: true,
    hasHoverState: true,
    zoomOnClusterClick: true,
  },
  [LayerName.DETECTION_AREA]: {
    zIndex: 85,
  },
  [LayerName.CORRIDOR]: {
    zIndex: 80,
    selectable: true,
  },
  [LayerName.APPROACHES]: {
    zIndex: 75,
    selectable: true,
  },
  [LayerName.SEGMENTS]: {
    zIndex: 70,
    selectable: true,
    dragBox: true,
  },
  [LayerName.BUS_STOPS]: {
    visible: false,
    zIndex: 65,
    selectable: true,
  },
  [LayerName.OSM_NODES]: {
    cluster: true,
    zIndex: 60,
    selectable: true,
    dragBox: true,
  },
  [LayerName.OSM_EDGES]: {
    zIndex: 55,
    selectable: true,
    dragBox: true,
    centerOnCreate: true,
  },
};
