import { useState } from 'react';
import { Checkbox, FormControlLabel, Button, IconButton } from '@material-ui/core';
import BaseLayer from 'ol/layer/Base';
import LayerGroup from 'ol/layer/Group';
import { ArrowLeft } from '@material-ui/icons';
import VectorLayer from 'ol/layer/Vector';
import { TileName } from 'components/EditGrid/utils';
import { useRerenderFromMap } from 'components/EditGrid/hooks';
import { layersMenuItems as menuItems } from 'components/EditGrid/consts';
import { MapUtils } from 'components/EditGrid/utils/mapUtils';
import styles from './LayerMenu.module.scss';

interface LayersMenuProps {
  map: MapUtils;
  className?: string;
}

export const LayersMenu: React.FC<LayersMenuProps> = ({ map, className }) => {
  /* ---------------------------------- Hooks --------------------------------- */
  useRerenderFromMap(map);

  /* -------------------------------- Selectors ------------------------------- */

  /* ---------------------------------- State --------------------------------- */
  const [showAllActive, setShowAllActive] = useState<boolean>(false);

  /* -------------------------------- Variables ------------------------------- */
  const MENU_EXPANDED_PROP = 'group-submenu-expanded';

  const rootLayers: BaseLayer[] = [];

  menuItems.forEach((name) => {
    const layer = map?.getLayer(name);
    if (
      !layer ||
      (layer instanceof VectorLayer && !layer.getSource().getFeatures().length) ||
      (layer instanceof LayerGroup && !layer.getLayers().getLength())
    )
      return;

    rootLayers.push(layer);
  });

  /* -------------------------------- Functions ------------------------------- */
  const getAllMenuLayers = () =>
    rootLayers.reduce((acc, current) => {
      const children = map?.getLayers({ group: current }) || [];
      return [...acc, ...children];
    }, rootLayers);

  const onShowHideAll = () => {
    getAllMenuLayers().forEach((layer) => {
      if (!(layer instanceof LayerGroup)) {
        layer.setVisible(showAllActive);
      }
    });
    setShowAllActive(!showAllActive);
  };

  const onCheckboxClick = (layer: BaseLayer, checked: boolean) => {
    if (layer instanceof LayerGroup) {
      map?.getLayers({ group: layer }).forEach((l) => l.setVisible(checked));
    } else {
      layer.setVisible(checked);
    }
  };

  const toggleSubmenu = (layer: LayerGroup) => {
    layer.set(MENU_EXPANDED_PROP, !layer.get(MENU_EXPANDED_PROP));
  };

  const changeTileSource = () => {
    if (map) map.tile = map.tileName === TileName.OSM ? TileName.MS_ATLAS : TileName.OSM;
  };

  /* --------------------------------- Effects -------------------------------- */

  if (!map) return null;

  const ListItem: React.FC<{ layer: BaseLayer; parent?: LayerGroup }> = ({ layer }) =>
    layer instanceof LayerGroup && !map.getChildren(layer).length ? null : (
      <div className={styles.menuItem}>
        <div className={styles.menuItemContent}>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label={layer.get('name')}
            onChange={(_, checked: boolean) => onCheckboxClick(layer, checked)}
            checked={
              layer instanceof LayerGroup ? map.getChildren(layer).some((l) => l.getVisible()) : layer.getVisible()
            }
          />
          {layer instanceof LayerGroup && (
            <IconButton
              className={`${styles.expandMenuIcon} ${layer.get(MENU_EXPANDED_PROP) ? styles.expandMenuIconActive : ''}`}
              onClick={() => toggleSubmenu(layer)}
            >
              <ArrowLeft />
            </IconButton>
          )}
        </div>
        {layer instanceof LayerGroup && layer.get(MENU_EXPANDED_PROP) && (
          <div className={styles.submenu}>
            <div className={styles.submenuContent}>
              {map.getChildren(layer).map((sublayer: BaseLayer) => (
                <ListItem layer={sublayer} parent={layer} key={sublayer.get('name') + sublayer.get('ids')} />
              ))}
            </div>
          </div>
        )}
      </div>
    );

  return (
    <div className={`${styles.layersMenu} ${className}`}>
      <div className={styles.heading}>Map Layers</div>
      <div className={styles.menuList}>
        {rootLayers.map((layer) => (
          <ListItem key={layer.get('name')} layer={layer} />
        ))}
      </div>
      <div className={styles.buttons}>
        <Button variant="outlined" color="primary" fullWidth onClick={onShowHideAll}>
          {showAllActive ? 'Show all layers' : 'Hide all layers'}
        </Button>
        <Button variant="outlined" color="primary" fullWidth onClick={changeTileSource}>
          {map.tileName === TileName.OSM ? 'Switch to english tiles' : 'Switch to OSM'}
        </Button>
      </div>
    </div>
  );
};
