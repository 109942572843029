import { TenantId } from 'enums';
import { TenantOptions, TenantOptionsGroupName as Group } from 'components/EditGrid/types';

const groups: TenantOptions = {
  [Group.TEL_AVIV]: {
    view: {
      coords: [34.7818, 32.0853],
    },
  },
  [Group.BOLZANO]: {
    view: {
      coords: [11.3548, 46.4983],
      zoom: 14,
    },
  },
};

export const tenantsOptions: TenantOptions = {
  [TenantId.TEL_AVIV]: groups[Group.TEL_AVIV],
  [TenantId.AXILION_CSP]: groups[Group.TEL_AVIV],
  [TenantId.AXILION_TEST]: groups[Group.TEL_AVIV],
  [TenantId.AXILION_DEV]: groups[Group.TEL_AVIV],
  [TenantId.AXILION_QA]: groups[Group.TEL_AVIV],
  [TenantId.BOSTON]: {
    view: {
      coords: [-71.0589, 42.3601],
      zoom: 12,
    },
  },
  [TenantId.NEW_YORK]: {
    view: {
      coords: [-74.006, 40.7128],
      zoom: 11,
    },
  },
  [TenantId.DUBAI]: {
    view: {
      coords: [55.2708, 25.2048],
      zoom: 10,
    },
  },
  [TenantId.JERUSALEM]: {
    view: {
      coords: [35.2137, 31.7683],
      zoom: 12,
    },
  },
  [TenantId.BOLZANO_CLIENT]: groups[Group.BOLZANO],
  [TenantId.BOLZANO_STAGE]: groups[Group.BOLZANO],
};
