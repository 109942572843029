import { AxiosInstance, AxiosError } from 'axios';
import store from 'store';
import { decrementLoadingCount, incrementLoadingCount, logOut } from 'features';

import { showErrorToast } from 'utils';
import { StatusCodes } from 'enums';

export const interceptRequests = (api: AxiosInstance) => {
  api.interceptors.request.use((request) => {
    store.dispatch(incrementLoadingCount());

    return request;
  });

  api.interceptors.response.use(
    (response) => {
      setTimeout(() => store.dispatch(decrementLoadingCount()), 200);
      return response;
    },
    (error: AxiosError) => {
      store.dispatch(decrementLoadingCount());
      showErrorToast(error);
      if (error.response?.status === StatusCodes.UNAUTHORIZED) {
        store.dispatch(logOut());
      }
      return Promise.reject(error);
    },
  );
};
