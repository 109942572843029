import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { fetchCameras, registerCamera, deregisterCamera } from 'features';
import { CameraSliceState, Camera } from 'interfaces';

const initialState: CameraSliceState = {
  cameras: [],
  loadingFetchCameras: 'idle',
  loadingRegisterCamera: 'idle',
  loadingDeregisterCamera: 'idle',
};

export const cameraSlice = createSlice({
  initialState,
  name: 'camera',
  reducers: {
    setLoadingRegisterCamera: (state, action: PayloadAction<string>) => {
      state.loadingRegisterCamera = action.payload;
    },
    setLoadingDeregisterCamera: (state, action: PayloadAction<string>) => {
      state.loadingDeregisterCamera = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCameras.pending, (state) => {
      state.loadingFetchCameras = 'loading';
    });
    builder.addCase(fetchCameras.fulfilled, (state, action: PayloadAction<Array<Camera>>) => {
      state.cameras = action.payload;
      state.loadingFetchCameras = 'loaded';
    });

    builder.addCase(registerCamera.pending, (state) => {
      state.loadingRegisterCamera = 'loading';
    });

    builder.addCase(registerCamera.fulfilled, (state) => {
      state.loadingRegisterCamera = 'loaded';
    });

    builder.addCase(registerCamera.rejected, (state) => {
      state.loadingRegisterCamera = 'error';
    });

    builder.addCase(deregisterCamera.pending, (state) => {
      state.loadingDeregisterCamera = 'loading';
    });

    builder.addCase(deregisterCamera.fulfilled, (state) => {
      state.loadingDeregisterCamera = 'loaded';
    });

    builder.addCase(deregisterCamera.rejected, (state) => {
      state.loadingDeregisterCamera = 'error';
    });
  },
});

export const selectCameras = ({ camera }: RootState): Array<Camera> => camera.cameras;
export const selectLoadingFetchCameras = ({ camera }: RootState): string => camera.loadingFetchCameras;
export const selectRegisteredCameraIds = ({ camera }: RootState): Array<string> =>
  camera.cameras.filter((cam) => !cam.deregistrationDate).map((cam) => cam.cameraId);
export const selectLoadingRegisterCamera = ({ camera }: RootState): string => camera.loadingRegisterCamera;
export const selectLoadingDeregisterCamera = ({ camera }: RootState): string => camera.loadingDeregisterCamera;

export const { setLoadingRegisterCamera, setLoadingDeregisterCamera } = cameraSlice.actions;
