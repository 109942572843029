import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { registerCamera, fetchCameras, selectLoadingRegisterCamera, setLoadingRegisterCamera } from 'features';
import styles from './RegisterCamera.module.scss';

export const RegisterCamera: React.FC = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [vehicle, setVehicle] = useState('private');
  const [cameraIdError, setCameraIdError] = useState(false);
  const [freeTextError, setFreeTextError] = useState(false);
  const [selectedDate, setSelectedDate] = useState<MaterialUiPickersDate>(new Date());
  const loadingRegisterCamera = useSelector(selectLoadingRegisterCamera);

  const openDialog = () => setOpen(true);
  const closeDialog = () => {
    setOpen(false);
    setCameraIdError(false);
    setFreeTextError(false);
  };
  const changeDate = (date: MaterialUiPickersDate) => setSelectedDate(date);

  const submitRegisterCamera = async (event: any) => {
    event.preventDefault();

    const cameraId = event.target[0]?.value;
    const vehicleType = event.target[1]?.value;
    const freeText = event.target[2]?.value;
    const date = event.target[3]?.value;

    if (!cameraId || !vehicleType || !freeText || !date) {
      setCameraIdError(!cameraId);
      setFreeTextError(!freeText);
    } else {
      dispatch(registerCamera({ cameraId, vehicleType, freeText, date }));
    }
  };

  useEffect(() => {
    if (loadingRegisterCamera === 'loaded') {
      closeDialog();
      dispatch(fetchCameras());
      dispatch(setLoadingRegisterCamera('idle'));
    }
  }, [loadingRegisterCamera]);

  return (
    <>
      <Button onClick={openDialog} variant="outlined" className={styles.registerButton}>
        Register camera
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Register camera</DialogTitle>
        <form onSubmit={submitRegisterCamera}>
          <DialogContent className={styles.dialogContent}>
            <TextField autoFocus label="Camera ID" error={cameraIdError} />

            <FormControl>
              <InputLabel>Vehicle type</InputLabel>
              <Select
                value={vehicle}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => setVehicle(String(e.target.value))}
              >
                <MenuItem value="private">Private</MenuItem>
                <MenuItem value="public">Public</MenuItem>
              </Select>
            </FormControl>

            <TextField label="Free text" error={freeTextError} />

            <KeyboardDatePicker
              variant="inline"
              format="yyyy-MM-dd"
              margin="normal"
              value={selectedDate}
              onChange={changeDate}
              autoOk
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button type="submit">Register</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
