import { useSelector, useDispatch } from 'react-redux';
import { DialogName } from 'enums';
import { selectContextSelectedCorridor, handleDeleteCorridor } from 'features';
import { MainDialog } from 'components/EditGrid/components/Dialogs/MainDialog';

export const DeleteCorridor = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const corridorId = useSelector(selectContextSelectedCorridor);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const onSubmit = () => {
    if (corridorId) dispatch(handleDeleteCorridor(corridorId));
  };

  /* --------------------------------- Effects -------------------------------- */

  return (
    <MainDialog name={DialogName.DELETE_CORRIDOR} onSubmit={onSubmit}>
      {`ID: ${corridorId}`}
    </MainDialog>
  );
};
