import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import {
  fetchIntersections,
  selectLoadingIntersections,
  selectIntersections,
  selectLoadingCreateIntersection,
  selectLoadingCreateSegment,
  resetLoadingIntersections,
  resetLoadingCreateIntersection,
  setStartEndIntersections,
  setContextSelectedIntersection,
  resetLoadingDeleteIntersection,
  selectLoadingDeleteIntersection,
} from 'features';

import { MapUtils } from 'components/EditGrid/utils';
import { LayerName } from 'components/EditGrid/types';

export const useIntersections = (map: MapUtils | undefined) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();
  const startFeature = useRef<Feature | null>();

  /* -------------------------------- Selectors ------------------------------- */
  const loadingIntersections = useSelector(selectLoadingIntersections);
  const intersectionsGeoJSON = useSelector(selectIntersections);
  const loadingCreateIntersection = useSelector(selectLoadingCreateIntersection);
  const loadingDeleteIntersection = useSelector(selectLoadingDeleteIntersection);
  const loadingCreateSegment = useSelector(selectLoadingCreateSegment);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */
  const selection = map?.selectedFeatures[LayerName.INTERSECTIONS];

  /* -------------------------------- Functions ------------------------------- */
  const handleIntersectionsLoaded = () => {
    map?.setLayer({ name: LayerName.INTERSECTIONS, geojson: intersectionsGeoJSON });
  };

  const setLayerVisible = () => {
    map?.getLayer(LayerName.INTERSECTIONS).setVisible(true);
  };

  const reselectStartIntersection = () => {
    if (!selection) return;
    const features = selection.getArray();
    const selectedFeature = features[features.length - 1];
    if (!selectedFeature) {
      startFeature.current = null;
    } else if (!startFeature.current) {
      startFeature.current = selectedFeature;
    } else {
      selection.push(startFeature.current);
      startFeature.current = null;
    }
  };

  const dispatchSelectedData = () => {
    if (!selection) return;
    const features = selection.getArray();
    const start = features[0]?.get('features')[0].getId();
    const end = features[1]?.get('features')[0].getId();
    dispatch(setStartEndIntersections([start, end]));
  };

  const dispatchContextClickData = (layer: VectorLayer, feature: Feature) => {
    if (layer?.get('name') === LayerName.INTERSECTIONS && feature) {
      const cluster = feature.get('features').length > 1;
      const id = feature.get('features')[0].getId();
      if (!cluster) dispatch(setContextSelectedIntersection(id));
    } else {
      dispatch(setContextSelectedIntersection(null));
    }
  };

  const initEvents = () => {
    map?.on('contextmenu', dispatchContextClickData);
    selection?.on('change', () => {
      reselectStartIntersection();
      dispatchSelectedData();
    });
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (map) {
      dispatch(fetchIntersections());
      initEvents();
    }
  }, [map]);

  useEffect(() => {
    if (loadingIntersections === 'loaded') {
      dispatch(resetLoadingIntersections());
      handleIntersectionsLoaded();
    }
  }, [loadingIntersections]);

  useEffect(() => {
    if (loadingCreateIntersection === 'loaded') {
      dispatch(resetLoadingCreateIntersection());
      dispatch(fetchIntersections());
      setLayerVisible();
    }
  }, [loadingCreateIntersection]);

  useEffect(() => {
    if (loadingDeleteIntersection === 'loaded') {
      dispatch(resetLoadingDeleteIntersection());
      dispatch(fetchIntersections());
    }
  });

  useEffect(() => {
    if (loadingCreateSegment === 'loaded') {
      selection?.clear();
    }
  }, [loadingCreateSegment]);
};
