import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { EditGridSliceState as State } from 'interfaces';
import { handleUploadBackup } from 'features';

const initialState: State = {
  contextClickPixels: [],
  contextClickLonLat: [],
  drawnGeofenceArea: [],
  drawnDetectionArea: [],
  startEndIntersections: null,
  selectedApproaches: [],
  selectedSegments: [],
  selectedSegmentsMaxSpeed: null,
  selectedEdgesNames: [],
  selectedEdgesCoords: [],
  selectedEdgesMaxSpeed: null,
  selectedNodesSignalized: null,
  contextSelectedCorridor: null,
  contextSelectedApproach: null,
  contextSelectedBusStop: null,
  contextSelectedIntersection: null,
  contextSelectedSegment: null,
  loadingUploadBackup: 'idle',
};

export const editGridSlice = createSlice({
  initialState,
  name: 'editGrid',
  reducers: {
    resetEditGridSlice: () => initialState,

    setContextClickPixels: (state, { payload }: PayloadAction<State['contextClickPixels']>) => {
      state.contextClickPixels = payload;
    },
    setContextClickLonLat: (state, { payload }: PayloadAction<State['contextClickLonLat']>) => {
      state.contextClickLonLat = payload;
    },
    setDrawnGeofenceArea: (state, { payload }: PayloadAction<State['drawnGeofenceArea']>) => {
      state.drawnGeofenceArea = payload;
    },
    setDrawnDetectionArea: (state, { payload }: PayloadAction<State['drawnDetectionArea']>) => {
      state.drawnDetectionArea = payload;
    },
    setStartEndIntersections: (state, { payload }: PayloadAction<State['startEndIntersections']>) => {
      state.startEndIntersections = payload;
    },
    setSelectedApproaches: (state, { payload }: PayloadAction<State['selectedApproaches']>) => {
      state.selectedApproaches = payload;
    },
    setSelectedSegments: (state, { payload }: PayloadAction<State['selectedSegments']>) => {
      state.selectedSegments = payload;
    },
    setSelectedSegmentsMaxSpeed: (state, { payload }: PayloadAction<State['selectedSegmentsMaxSpeed']>) => {
      state.selectedSegmentsMaxSpeed = payload;
    },
    setSelectedEdgesNames: (state, { payload }: PayloadAction<State['selectedEdgesNames']>) => {
      state.selectedEdgesNames = payload;
    },
    setSelectedEdgesCoords: (state, { payload }: PayloadAction<State['selectedEdgesCoords']>) => {
      state.selectedEdgesCoords = payload;
    },
    setSelectedEdgesMaxSpeed: (state, { payload }: PayloadAction<State['selectedEdgesMaxSpeed']>) => {
      state.selectedEdgesMaxSpeed = payload;
    },
    setSelectedNodesSignalized: (state, { payload }: PayloadAction<State['selectedNodesSignalized']>) => {
      state.selectedNodesSignalized = payload;
    },
    setContextSelectedCorridor: (state, { payload }: PayloadAction<State['contextSelectedCorridor']>) => {
      state.contextSelectedCorridor = payload;
    },
    setContextSelectedApproach: (state, { payload }: PayloadAction<State['contextSelectedApproach']>) => {
      state.contextSelectedApproach = payload;
    },
    setContextSelectedBusStop: (state, { payload }: PayloadAction<State['contextSelectedBusStop']>) => {
      state.contextSelectedBusStop = payload;
    },
    setContextSelectedIntersection: (state, { payload }: PayloadAction<State['contextSelectedIntersection']>) => {
      state.contextSelectedIntersection = payload;
    },
    setContextSelectedSegment: (state, { payload }: PayloadAction<State['contextSelectedSegment']>) => {
      state.contextSelectedSegment = payload;
    },
    resetLoadingUploadBackup: (state) => {
      state.loadingUploadBackup = 'idle';
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(handleUploadBackup.fulfilled, (state) => {
      state.loadingUploadBackup = 'loaded';
    });
  },
});

export const selectContextClickPixels = ({ editGrid }: RootState) => editGrid.contextClickPixels;
export const selectContextClickLonLat = ({ editGrid }: RootState) => editGrid.contextClickLonLat;
export const selectStartEndIntersections = ({ editGrid }: RootState) => editGrid.startEndIntersections;
export const selectDrawnGeofenceArea = ({ editGrid }: RootState) => editGrid.drawnGeofenceArea;
export const selectDrawnDetectionArea = ({ editGrid }: RootState) => editGrid.drawnDetectionArea;
export const selectSelectedApproaches = ({ editGrid }: RootState) => editGrid.selectedApproaches;
export const selectSelectedSegments = ({ editGrid }: RootState) => editGrid.selectedSegments;
export const selectSelectedSegmentsMaxSpeed = ({ editGrid }: RootState) => editGrid.selectedSegmentsMaxSpeed;
export const selectSelectedEdgesNames = ({ editGrid }: RootState) => editGrid.selectedEdgesNames;
export const selectSelectedEdgesCoords = ({ editGrid }: RootState) => editGrid.selectedEdgesCoords;
export const selectSelectedEdgesMaxSpeed = ({ editGrid }: RootState) => editGrid.selectedEdgesMaxSpeed;
export const selectSelectedNodesSignalized = ({ editGrid }: RootState) => editGrid.selectedNodesSignalized;
export const selectContextSelectedCorridor = ({ editGrid }: RootState) => editGrid.contextSelectedCorridor;
export const selectContextSelectedApproach = ({ editGrid }: RootState) => editGrid.contextSelectedApproach;
export const selectContextSelectedBusStop = ({ editGrid }: RootState) => editGrid.contextSelectedBusStop;
export const selectContextSelectedIntersection = ({ editGrid }: RootState) => editGrid.contextSelectedIntersection;
export const selectContextSelectedSegment = ({ editGrid }: RootState) => editGrid.contextSelectedSegment;
export const selectLoadingUploadBackup = ({ editGrid }: RootState) => editGrid.loadingUploadBackup;

export const {
  resetEditGridSlice,
  setContextClickPixels,
  setContextClickLonLat,
  setDrawnGeofenceArea,
  setStartEndIntersections,
  setSelectedApproaches,
  setSelectedSegments,
  setSelectedSegmentsMaxSpeed,
  setSelectedEdgesNames,
  setSelectedNodesSignalized,
  setContextSelectedCorridor,
  setContextSelectedApproach,
  setContextSelectedBusStop,
  setSelectedEdgesCoords,
  setSelectedEdgesMaxSpeed,
  setContextSelectedIntersection,
  setContextSelectedSegment,
  setDrawnDetectionArea,
  resetLoadingUploadBackup,
} = editGridSlice.actions;
