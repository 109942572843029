import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { timeFromArray, timeToArray } from 'utils';
import { selectTimeIntervals, editTimeIntervals } from 'features';
import { DialogName } from 'enums';
import { MainDialog } from 'components/EditGrid/components/Dialogs/MainDialog';

export const EditTimeIntervals = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const timeIntervals = useSelector(selectTimeIntervals);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const onSubmit = ({ currentTarget }: React.FormEvent<HTMLFormElement>) =>
    dispatch(
      editTimeIntervals(
        timeIntervals.map(({ id, label }) => ({
          id,
          label,
          startTime: timeToArray(currentTarget[`start${id}`].value),
          endTime: timeToArray(currentTarget[`end${id}`].value),
        })),
      ),
    );

  /* --------------------------------- Effects -------------------------------- */

  return (
    <MainDialog
      name={DialogName.SET_TIME_INTERVALS}
      onSubmit={onSubmit}
      style={{ gridTemplateColumns: 'repeat(4, max-content)' }}
    >
      <strong>ID</strong>
      <strong>Label</strong>
      <strong>Start time</strong>
      <strong>End time</strong>
      {timeIntervals.map(({ id, label, startTime, endTime }) => (
        <React.Fragment key={id}>
          <div>{id}</div>
          <div>{label}</div>
          <TextField
            name={`start${id}`}
            defaultValue={timeFromArray(startTime)}
            type="time"
            inputProps={{ step: 1800 }}
            required
          />
          <TextField
            name={`end${id}`}
            defaultValue={timeFromArray(endTime)}
            type="time"
            inputProps={{ step: 1800 }}
            required
          />
        </React.Fragment>
      ))}
    </MainDialog>
  );
};
