import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSegments, createSegment, deleteSegment } from 'api';
import { CreateSegmentRequest, DeleteSegmentRequest } from 'interfaces';

export const fetchSegments = createAsyncThunk('segments/fetch', async () => {
  const { data } = await getSegments();
  return data;
});

export const handleCreateSegment = createAsyncThunk('segments/create', async (requestData: CreateSegmentRequest) => {
  const { data } = await createSegment(requestData);
  return data;
});

export const handleDeleteSegment = createAsyncThunk('segments/delete', async (requestData: DeleteSegmentRequest) => {
  const { data } = await deleteSegment(requestData);
  return data;
});
