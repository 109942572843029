export enum DialogName {
  SET_TIME_INTERVALS = 'Set Time Intervals',
  CREATE_GEOFENCE_AREA = 'Add Geofence Area',
  CREATE_DETECTION_AREA = 'Add Detection Area',
  CREATE_CORRIDOR = 'Create Corridor',
  DELETE_CORRIDOR = 'Delete Corridor',
  CREATE_APPROACH = 'Add Approach',
  DELETE_APPROACH = 'Delete Approach',
  EDIT_BUSSTOP = 'Edit Bus Stop',
  DELETE_BUSSTOP = 'Delete Bus Stop',
  CREATE_INTERSECTION = 'Add Intersection',
  DELETE_INTERSECTION = 'Delete Intersection',
  CREATE_SEGMENT = 'Add Segment',
  DELETE_SEGMENT = 'Delete Segment',
}
