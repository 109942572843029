import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { fetchSchedules, handleCreateSchedule, handleUpdateSchedule, handleDeleteSchedule } from 'features';
import { ScheduleSliceState } from 'interfaces';

const initialState: ScheduleSliceState = {
  schedules: [],
  loadingFetchSchedules: 'idle',
  loadingCreateSchedule: 'idle',
  loadingUpdateSchedule: 'idle',
  loadingDeleteSchedule: 'idle',
};

export const scheduleSlice = createSlice({
  initialState,
  name: 'schedule',
  reducers: {
    resetLoadingDeleteSchedule: (state) => {
      state.loadingDeleteSchedule = 'idle';
    },
    setLoadingCreateSchedule: (state, action: PayloadAction<string>) => {
      state.loadingCreateSchedule = action.payload;
    },
    setLoadingUpdateSchedule: (state, action: PayloadAction<string>) => {
      state.loadingUpdateSchedule = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSchedules.pending, (state) => {
      state.loadingFetchSchedules = 'pending';
    });
    builder.addCase(fetchSchedules.fulfilled, (state, action: PayloadAction<Array<string>>) => {
      state.schedules = action.payload.reverse();
      state.loadingFetchSchedules = 'loaded';
    });

    builder.addCase(handleCreateSchedule.pending, (state) => {
      state.loadingCreateSchedule = 'loading';
    });

    builder.addCase(handleCreateSchedule.fulfilled, (state) => {
      state.loadingCreateSchedule = 'loaded';
    });

    builder.addCase(handleCreateSchedule.rejected, (state) => {
      state.loadingCreateSchedule = 'error';
    });

    builder.addCase(handleUpdateSchedule.pending, (state) => {
      state.loadingUpdateSchedule = 'loading';
    });

    builder.addCase(handleUpdateSchedule.fulfilled, (state) => {
      state.loadingUpdateSchedule = 'loaded';
    });

    builder.addCase(handleUpdateSchedule.rejected, (state) => {
      state.loadingUpdateSchedule = 'error';
    });

    builder.addCase(handleDeleteSchedule.fulfilled, (state) => {
      state.loadingDeleteSchedule = 'loaded';
    });
  },
});

export const selectSchedules = ({ schedule }: RootState): Array<string> => schedule.schedules;
export const selectLoadingFetchSchedules = ({ schedule }: RootState): string => schedule.loadingFetchSchedules;
export const selectLoadingCreateSchedule = ({ schedule }: RootState): string => schedule.loadingCreateSchedule;
export const selectLoadingUpdateSchedule = ({ schedule }: RootState): string => schedule.loadingUpdateSchedule;
export const selectLoadingDeleteSchedule = ({ schedule }: RootState): string => schedule.loadingDeleteSchedule;

export const { setLoadingCreateSchedule, setLoadingUpdateSchedule, resetLoadingDeleteSchedule } = scheduleSlice.actions;
