import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes, TenantId } from 'enums';
import { getUserInfo, setTenant, getTenants } from 'api';
import { UserInfoResponse } from 'interfaces';
import { RejectedValue } from 'interfaces/RejectedValue';

export const fetchUser = createAsyncThunk<UserInfoResponse, void, { rejectValue: RejectedValue }>(
  'user/fetchUser',
  async (_, thunkAPI) => {
    // const principalRes = await getPrincipal();
    const userInfoRes = await getUserInfo();

    if (userInfoRes.status && userInfoRes.status !== StatusCodes.OK) {
      return thunkAPI.rejectWithValue({ error: userInfoRes.statusText });
    }

    return {
      // ...principalRes.data,
      ...userInfoRes.data,
    };
  },
);

export const setActiveTenant = createAsyncThunk<TenantId, TenantId, { rejectValue: RejectedValue }>(
  'user/setActiveTenant',
  async (tenantId: TenantId, thunkAPI) => {
    const setTenantRes = await setTenant(tenantId);

    if (setTenantRes.status && setTenantRes.status !== StatusCodes.OK) {
      return thunkAPI.rejectWithValue({ error: setTenantRes.statusText });
    }

    return tenantId;
  },
);

export const fetchTenants = createAsyncThunk<Array<string>, void, { rejectValue: RejectedValue }>(
  'user/fetchTenants',
  async (_, thunkAPI) => {
    const tenantsRes = await getTenants();

    if (tenantsRes.status && tenantsRes.status !== StatusCodes.OK) {
      return thunkAPI.rejectWithValue({ error: tenantsRes.statusText });
    }

    return tenantsRes.data;
  },
);
