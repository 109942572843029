import { useSelector, useDispatch } from 'react-redux';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import {
  selectSelectedEdgesNames,
  selectSelectedNodesSignalized,
  selectContextClickLonLat,
  handleCreateIntersection,
} from 'features';
import { DialogName } from 'enums';

import { MainDialog } from 'components/EditGrid/components/Dialogs/MainDialog';

export const CreateIntersection = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const edgesNames = useSelector(selectSelectedEdgesNames).join('-');
  const signalized = !!useSelector(selectSelectedNodesSignalized);
  const coords = useSelector(selectContextClickLonLat);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const onSubmit = ({ currentTarget }: React.FormEvent<HTMLFormElement>) =>
    dispatch(
      handleCreateIntersection({
        id: currentTarget.intersectionId.value,
        name: currentTarget.intersectionName.value,
        lat: currentTarget.lat.value,
        lon: currentTarget.lon.value,
        signalized: currentTarget.signalized.checked,
      }),
    );

  /* --------------------------------- Effects -------------------------------- */

  return (
    <MainDialog name={DialogName.CREATE_INTERSECTION} onSubmit={onSubmit}>
      <TextField
        name="intersectionName"
        label="Name"
        defaultValue={edgesNames}
        multiline
        required
        style={{ gridColumn: '1/-1' }}
      />
      <TextField name="lat" label="Latitude" defaultValue={coords[1]} required />
      <TextField name="lon" label="Longitude" defaultValue={coords[0]} required />
      <TextField name="intersectionId" label="ID" />
      <FormControlLabel
        label="Is signalized"
        control={<Checkbox name="signalized" defaultChecked={signalized} color="primary" />}
        style={{ gridColumn: 1 }}
      />
    </MainDialog>
  );
};
