import { createAsyncThunk } from '@reduxjs/toolkit';
import { getIntersections, createIntersection, deleteIntersection } from 'api';
import { CreateIntersectionRequest, DeleteIntersectionRequest } from 'interfaces';

export const fetchIntersections = createAsyncThunk('intersections/fetch', async () => {
  const { data } = await getIntersections();
  return data;
});

export const handleCreateIntersection = createAsyncThunk(
  'intersections/create',
  async (requestData: CreateIntersectionRequest) => {
    const { data } = await createIntersection(requestData);
    return data;
  },
);

export const handleDeleteIntersection = createAsyncThunk(
  'intersections/delete',
  async (requestData: DeleteIntersectionRequest) => {
    const { data } = await deleteIntersection(requestData);
    return data;
  },
);
