import axios, { AxiosPromise } from 'axios';
import {
  PRINCIPAL,
  USER_INFO,
  ADMIN_TENANT,
  ADMIN_TENANTS,
  ADMIN_CAMERAS,
  REGISTER,
  DEREGISTER,
  JOBS,
  ADMIN_SCHEDULER,
  ADMIN_SCHEDULERS,
  INTERSECTIONS,
  APPROACHES,
  BUS_STOPS,
  OSMEXPORT,
  GEOFENCEAREA,
  CORRIDORS,
  ADMIN_CORRIDOR,
  OSM_BUS_STOPS,
  TIME_INTERVALS,
  CREATE_APPROACH,
  ADMIN_APPROACH,
  EDIT_BUS_STOP,
  ADMIN_BUS_STOP,
  ADD_INTERSECTION,
  ADD_SEGMENT,
  ADMIN_SEGMENT,
  ADMIN_SEGMENTS,
  DIRECTIONS,
  ADMIN_INTERSECTION,
  INTERSECTION_AREA,
  BACKUP,
  RESTORE,
} from 'consts';
import {
  PrincipalResponse,
  UserInfoResponse,
  Camera,
  ScheduleRequest,
  DeleteIntersectionRequest,
  IntersectionsResponse,
  CreateIntersectionRequest,
  ApproachesResponse,
  CreateApproachRequest,
  SegmentsResponse,
  CreateSegmentRequest,
  DeleteSegmentRequest,
  BusStopsResponse,
  EditBusStopRequest,
  DeleteBusStopRequest,
  OsmExportResponse,
  GeofenceAreaRequest,
  GeofenceAreaResponse,
  CorridorsResponse,
  CreateCorridorRequest,
  DeleteCorridorRequest,
  TimeIntervalsResponse,
  EditTimeIntervalsRequest,
  DeleteApproachRequest,
  DirectionsResponse,
  DetectionAreaRequest,
  DetectionAreaResponse,
  CreateDetectionAreaRequest,
  DeregisterCamera,
} from 'interfaces';
import { getMeta } from 'utils/getMetaData';
import { api } from './api';

const { get, post, put, delete: del } = api;

const userInfoUrl = getMeta('userinfo');

export const getPrincipal = (): AxiosPromise<PrincipalResponse> => get(PRINCIPAL);
export const getUserInfo = (): AxiosPromise<UserInfoResponse> => {
  if (userInfoUrl && !userInfoUrl.includes('CLUSTER_USERINFO')) {
    return axios({
      method: 'get',
      url: userInfoUrl,
      withCredentials: true,
    });
  }

  return get(USER_INFO);
};
export const setTenant = (id: string): AxiosPromise<void> => post(`${ADMIN_TENANT}/${id}`);
export const getTenants = (): AxiosPromise<Array<string>> => get(ADMIN_TENANTS);
export const getCameras = (): AxiosPromise<Array<Camera>> => get(ADMIN_CAMERAS);
export const createSchedule = (data: ScheduleRequest, jobId: string): AxiosPromise<void> =>
  post(`${ADMIN_SCHEDULER}/${jobId}`, data);
export const deleteSchedule = (jobId: ScheduleRequest['schedule']): AxiosPromise => del(`${ADMIN_SCHEDULER}/${jobId}`);
export const updateSchedule = (data: ScheduleRequest, jobId: string): AxiosPromise<void> =>
  put(`${ADMIN_SCHEDULER}/${jobId}`, data);
export const getSchedules = (): AxiosPromise<Array<string>> => get(ADMIN_SCHEDULERS);
export const postRegisterCamera = (
  cameraId: string,
  vehicleType: string,
  freeText: string,
  date: string,
): AxiosPromise<undefined> =>
  post(`${ADMIN_CAMERAS}/${cameraId}/${REGISTER}/${vehicleType}?vehicle=${freeText}&date=${date}`);
export const postDeregisterCamera = ({ cameraId, date, reason }: DeregisterCamera): AxiosPromise<undefined> =>
  post(`${ADMIN_CAMERAS}/${cameraId}/${DEREGISTER}`, { date, reason });

export const runJobsPerCamera = (
  cameraId: string,
  type: string,
  startDate: string,
  endDate: string,
): AxiosPromise<undefined> => post(`${ADMIN_CAMERAS}/${cameraId}/${JOBS}/${type}?start=${startDate}&end=${endDate}`);

// Edit grid
export const getIntersections = (): AxiosPromise<IntersectionsResponse> => get(INTERSECTIONS);
export const createIntersection = (data: CreateIntersectionRequest): AxiosPromise => post(ADD_INTERSECTION, data);
export const deleteIntersection = (id: DeleteIntersectionRequest): AxiosPromise => del(`${ADMIN_INTERSECTION}/${id}`);
export const getApproaches = (): AxiosPromise<ApproachesResponse> => get(APPROACHES);
export const createApproach = (data: CreateApproachRequest): AxiosPromise => post(CREATE_APPROACH, data);
export const deleteApproach = (id: DeleteApproachRequest): AxiosPromise => del(`${ADMIN_APPROACH}/${id}`);
export const getSegments = (): AxiosPromise<SegmentsResponse> => get(ADMIN_SEGMENTS);
export const createSegment = (data: CreateSegmentRequest): AxiosPromise => post(ADD_SEGMENT, data);
export const deleteSegment = (id: DeleteSegmentRequest): AxiosPromise => del(`${ADMIN_SEGMENT}/${id}`);
export const getBusStops = (): AxiosPromise<BusStopsResponse> => get(BUS_STOPS);
export const editBusStop = (data: EditBusStopRequest): AxiosPromise => post(EDIT_BUS_STOP, data);
export const deleteBusStop = (id: DeleteBusStopRequest): AxiosPromise => del(`${ADMIN_BUS_STOP}/${id}`);
export const getOsmBusStops = (): AxiosPromise => get(OSM_BUS_STOPS);
export const getOsmexport = (): AxiosPromise<OsmExportResponse> => get(OSMEXPORT);
export const getGeofenceArea = (): AxiosPromise<GeofenceAreaResponse> => get(GEOFENCEAREA);
export const postGeofenceArea = (data: GeofenceAreaRequest): AxiosPromise => post(GEOFENCEAREA, data);
export const getCorridors = (): AxiosPromise<CorridorsResponse> => get(CORRIDORS);
export const createCorridor = (data: CreateCorridorRequest): AxiosPromise => post(ADMIN_CORRIDOR, data);
export const deleteCorridor = (id: DeleteCorridorRequest): AxiosPromise => del(`${ADMIN_CORRIDOR}/${id}`);
export const getTimeIntervals = (): AxiosPromise<TimeIntervalsResponse> => get(TIME_INTERVALS);
export const postTimeIntervals = (data: EditTimeIntervalsRequest): AxiosPromise => post(TIME_INTERVALS, data);
export const getDirections = (): AxiosPromise<DirectionsResponse> => get(DIRECTIONS);
export const getDetectionArea = (id: DetectionAreaRequest): AxiosPromise<DetectionAreaResponse> =>
  get(`${INTERSECTION_AREA}/${id}`);
export const postDetectionArea = (data: CreateDetectionAreaRequest): AxiosPromise => post(INTERSECTION_AREA, data);
export const getBackup = (): AxiosPromise => get(BACKUP);
export const postRestore = (data: any): AxiosPromise => post(RESTORE, data);
