import { ReactElement, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { selectActiveDialog, setActiveDialog } from 'features';
import { DialogName } from 'enums';
import styles from './MainDialog.module.scss';

interface MainDialogProps {
  name: DialogName;
  title?: ReactElement | string;
  children?: React.ReactNode;
  className?: string;
  style?: object;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '';
  onSubmit?: Function;
  onClose?: Function;
  closeOnSubmit?: boolean;
}

export const MainDialog: React.FC<MainDialogProps> = ({
  name,
  title,
  children,
  className,
  style,
  width = '',
  onSubmit: submitCallback,
  onClose: closeCallback,
  closeOnSubmit = true,
}) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const activeDialog = useSelector(selectActiveDialog);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */
  const isOpen = name === activeDialog;

  /* -------------------------------- Functions ------------------------------- */
  const close = () => dispatch(setActiveDialog(null));

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (submitCallback) submitCallback(event);
    if (closeOnSubmit) close();
    event.preventDefault();
  };

  const handleClose = () => {
    if (closeCallback) closeCallback();
    close();
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (isOpen) {
      document.body.style.cursor = 'default';
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{ root: styles.root }}
      fullWidth={!!width}
      maxWidth={width || false}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle>{title || name}</DialogTitle>
        <DialogContent className={`${className} ${styles.content}`} style={style}>
          {children}
        </DialogContent>
        <DialogActions classes={{ root: styles.actions }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
