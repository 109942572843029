import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCorridors, deleteCorridor, createCorridor } from 'api';
import { CreateCorridorRequest, DeleteCorridorRequest } from 'interfaces';

export const fetchCorridors = createAsyncThunk('corridors/fetch', async () => {
  const { data } = await getCorridors();
  return data;
});

export const handleCreateCorridor = createAsyncThunk('corridors/create', async (requestData: CreateCorridorRequest) => {
  const { data } = await createCorridor(requestData);
  return data;
});

export const handleDeleteCorridor = createAsyncThunk('corridors/delete', async (requestData: DeleteCorridorRequest) => {
  const { data } = await deleteCorridor(requestData);
  return data;
});
