import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Fab, Menu, MenuItem } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { fetchOsmBusStops, setActiveDialog } from 'features';
import { downloadBackup, uploadBackup } from 'components/EditGrid/utils';
import { DialogName } from 'enums';

interface FabMenuProps {
  className?: string;
}

export const FabMenu: React.FC<FabMenuProps> = ({ className }) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */

  /* ---------------------------------- State --------------------------------- */
  const [isOpen, setIsOpen] = useState(false);

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const generateBusStops = () => dispatch(fetchOsmBusStops());
  const openTimeIntervalsDialog = () => dispatch(setActiveDialog(DialogName.SET_TIME_INTERVALS));

  /* --------------------------------- Effects -------------------------------- */

  return (
    <>
      <Fab color="primary" onClick={open} ref={buttonRef} className={className}>
        <SettingsIcon />
      </Fab>

      <Menu anchorEl={buttonRef.current} open={isOpen} onClose={close} onClick={close}>
        <MenuItem onClick={generateBusStops}>Generate bus stops from OSM</MenuItem>
        <MenuItem onClick={openTimeIntervalsDialog}>Set time intervals</MenuItem>
        <MenuItem onClick={downloadBackup}>Download</MenuItem>
        <MenuItem onClick={uploadBackup}>Uplaod</MenuItem>
      </Menu>
    </>
  );
};
