import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Feature } from 'ol';
import {
  selectLoadingOsmexport,
  resetLoadingOsmexport,
  selectOsmexportNodes,
  setSelectedNodesSignalized,
  selectLoadingCreateIntersection,
} from 'features';
import { MapUtils } from 'components/EditGrid/utils';
import { LayerName } from 'components/EditGrid/types';

export const useOsmNodes = (map: MapUtils | undefined) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const loadingOsmExport = useSelector(selectLoadingOsmexport);
  const osmNodesGeoJSON = useSelector(selectOsmexportNodes);
  const loadingCreateIntersection = useSelector(selectLoadingCreateIntersection);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */
  const selection = map?.selectedFeatures[LayerName.OSM_NODES];

  /* -------------------------------- Functions ------------------------------- */
  const handleOsmeexportLoaded = () => {
    map?.setLayer({ name: LayerName.OSM_NODES, geojson: osmNodesGeoJSON });
  };

  const dispatchSelectedData = () => {
    if (!selection) return;
    const hasSignal = selection
      .getArray()
      .some((feature) => feature.get('features').some((child: Feature) => child.get('type') === 'signal'));
    dispatch(setSelectedNodesSignalized(hasSignal));
  };

  const initEvents = () => {
    selection?.on('change', dispatchSelectedData);
  };

  /* --------------------------------- Effects -------------------------------- */

  // Data is fetched from useOsmEdges
  useEffect(() => {
    if (map) {
      initEvents();
    }
  }, [map]);

  useEffect(() => {
    if (loadingOsmExport === 'loaded') {
      dispatch(resetLoadingOsmexport());
      handleOsmeexportLoaded();
    }
  }, [loadingOsmExport]);

  useEffect(() => {
    if (loadingCreateIntersection === 'loaded') {
      selection?.clear();
    }
  }, [loadingCreateIntersection]);
};
