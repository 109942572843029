import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { selectNavigationIsOpen, setNavigationIsOpen } from 'features';
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import styles from './Navigation.module.scss';

export const Navigation = () => {
  const dispatch = useDispatch();
  const navigationIsOpen = useSelector(selectNavigationIsOpen);

  const closeDrawer = () => {
    dispatch(setNavigationIsOpen(false));
  };

  return (
    <Drawer open={navigationIsOpen} onClose={closeDrawer} className={styles.navigation}>
      <List onClick={closeDrawer} className={styles.navigationList}>
        <NavLink exact to="/cameras">
          <ListItem button>
            <ListItemText primary="Cameras" className={styles.navigationLink} />
          </ListItem>
        </NavLink>
        <NavLink exact to="/schedules">
          <ListItem button>
            <ListItemText primary="Schedules" className={styles.navigationLink} />
          </ListItem>
        </NavLink>
        <NavLink exact to="/editgrid">
          <ListItem button>
            <ListItemText primary="Edit grid" className={styles.navigationLink} />
          </ListItem>
        </NavLink>
      </List>
    </Drawer>
  );
};
