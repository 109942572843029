import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { OsmExportResponse, OsmexportSliceState } from 'interfaces';
import { FeatureCollection, Feature } from 'geojson';
import { LoadingState } from 'types';
import { fetchOsmexport } from './thunks';

const initialState: OsmexportSliceState = {
  edges: {
    geojson: {
      type: 'FeatureCollection',
      features: [],
    },
  },
  nodes: {
    geojson: {
      type: 'FeatureCollection',
      features: [],
    },
  },
  loading: 'idle',
};

export const osmExportSlice = createSlice({
  initialState,
  name: 'osmExport',
  reducers: {
    resetLoadingOsmexport: (state) => {
      state.loading = 'idle';
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchOsmexport.fulfilled, (state, action: PayloadAction<OsmExportResponse>) => {
      const edges: Feature[] = [];
      const nodes: Feature[] = [];
      action.payload.features.forEach((feature) => {
        if (feature.geometry.type === 'LineString') edges.push(feature);
        if (feature.geometry.type === 'Point') nodes.push(feature);
      });
      state.edges.geojson.features = edges;
      state.nodes.geojson.features = nodes;
      state.loading = 'loaded';
    });
  },
});

export const selectOsmexportEdges = ({ osmExport }: RootState): FeatureCollection => osmExport.edges.geojson;
export const selectOsmexportNodes = ({ osmExport }: RootState): FeatureCollection => osmExport.nodes.geojson;
export const selectLoadingOsmexport = ({ osmExport }: RootState): LoadingState => osmExport.loading;

export const { resetLoadingOsmexport } = osmExportSlice.actions;
