import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { DetectionAreaSliceState, DetectionAreaResponse } from 'interfaces';
import { fetchDetectionArea, createDetectionArea } from './thunks';

const initialState: DetectionAreaSliceState = {
  geojson: {
    type: 'Polygon',
    coordinates: [],
  },
  intersectionId: null,
  loading: 'idle',
  loadingCreate: 'idle',
};

export const detectionAreaSlice = createSlice({
  initialState,
  name: 'detectionArea',
  reducers: {
    resetLoadingDetectionArea: (state) => {
      state.loading = 'idle';
    },
    resetLoadingCreateDetectionArea: (state) => {
      state.loadingCreate = 'idle';
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchDetectionArea.fulfilled, (state, action: PayloadAction<DetectionAreaResponse>) => {
      if (action.payload.detectionArea.length) {
        state.geojson.coordinates = [action.payload.detectionArea];
      } else {
        state.geojson.coordinates = [];
      }
      state.loading = 'loaded';
    });
    addCase(createDetectionArea.fulfilled, (state) => {
      state.loadingCreate = 'loaded';
    });
  },
});

export const selectDetectionArea = ({ detectionArea }: RootState) => detectionArea.geojson;
export const selectLoadingDetectionArea = ({ detectionArea }: RootState) => detectionArea.loading;
export const selectLoadingCreateDetectionArea = ({ detectionArea }: RootState) => detectionArea.loadingCreate;

export const { resetLoadingDetectionArea, resetLoadingCreateDetectionArea } = detectionAreaSlice.actions;
