import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApproaches, createApproach, deleteApproach } from 'api';
import { CreateApproachRequest, DeleteApproachRequest } from 'interfaces';

export const fetchApproaches = createAsyncThunk('approaches/fetch', async () => {
  const { data } = await getApproaches();
  return data;
});

export const handleCreateApproach = createAsyncThunk(
  'approaches/create',
  async (requestData: CreateApproachRequest) => {
    const { data } = await createApproach(requestData);
    return data;
  },
);

export const handleDeleteApproach = createAsyncThunk('approaches/delete', async (id: DeleteApproachRequest) => {
  const { data } = await deleteApproach(id);
  return data;
});
