import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  cameraSlice,
  loadingSlice,
  userSlice,
  toastMessageSlice,
  scheduleSlice,
  appSlice,
  intersectionsSlice,
  approachesSlice,
  segmentsSlice,
  busStopsSlice,
  osmExportSlice,
  geofenceAreaSlice,
  detectionAreaSlice,
  corridorsSlice,
  editGridSlice,
  timeIntervalsSlice,
  directionsSlice,
} from 'features';

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    camera: cameraSlice.reducer,
    loading: loadingSlice.reducer,
    toastMessage: toastMessageSlice.reducer,
    schedule: scheduleSlice.reducer,
    app: appSlice.reducer,
    intersections: intersectionsSlice.reducer,
    approaches: approachesSlice.reducer,
    segments: segmentsSlice.reducer,
    busStops: busStopsSlice.reducer,
    osmExport: osmExportSlice.reducer,
    geofenceArea: geofenceAreaSlice.reducer,
    corridors: corridorsSlice.reducer,
    editGrid: editGridSlice.reducer,
    timeIntervals: timeIntervalsSlice.reducer,
    directions: directionsSlice.reducer,
    detectionArea: detectionAreaSlice.reducer,
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  ],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
