import { AxiosError } from 'axios';
import { setToastMessage } from 'features';
import store from 'store';
import { USER_INFO } from 'consts';
import { StatusCodes } from 'enums';

export const showErrorToast = (error: AxiosError) => {
  const { response, config } = error;
  const { method, url } = config;
  const msg = response?.data?.error || response?.data || response?.data?.message;
  const toastMessage = msg || `${method?.toUpperCase()} /${url} - ${response?.status}`;

  if (response?.status !== StatusCodes.UNAUTHORIZED && url !== USER_INFO) {
    store.dispatch(setToastMessage(toastMessage));
  }
};
