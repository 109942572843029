import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { SegmentsResponse, SegmentsSliceState } from 'interfaces';
import { fetchSegments, handleCreateSegment, handleDeleteSegment } from './thunks';

const initialState: SegmentsSliceState = {
  geojson: {
    type: 'FeatureCollection',
    features: [],
  },
  loading: 'idle',
  loadingCreate: 'idle',
  loadingDelete: 'idle',
};

export const segmentsSlice = createSlice({
  initialState,
  name: 'segments',
  reducers: {
    resetLoadingSegments: (state) => {
      state.loading = 'idle';
    },
    resetLoadingCreateSegment: (state) => {
      state.loadingCreate = 'idle';
    },
    resetLoadingDeleteSegment: (state) => {
      state.loadingDelete = 'idle';
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchSegments.fulfilled, (state, action: PayloadAction<SegmentsResponse>) => {
      state.geojson = action.payload;
      state.loading = 'loaded';
    });
    addCase(handleCreateSegment.fulfilled, (state) => {
      state.loadingCreate = 'loaded';
    });
    addCase(handleDeleteSegment.fulfilled, (state) => {
      state.loadingDelete = 'loaded';
    });
  },
});

export const selectSegments = ({ segments }: RootState) => segments.geojson;
export const selectLoadingSegments = ({ segments }: RootState) => segments.loading;
export const selectLoadingCreateSegment = ({ segments }: RootState) => segments.loadingCreate;
export const selectLoadingDeleteSegment = ({ segments }: RootState) => segments.loadingDelete;

export const { resetLoadingSegments, resetLoadingCreateSegment, resetLoadingDeleteSegment } = segmentsSlice.actions;
