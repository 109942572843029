import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { CorridorsSliceState, CorridorsResponse } from 'interfaces';
import { fetchCorridors, handleDeleteCorridor, handleCreateCorridor } from './thunks';

const initialState: CorridorsSliceState = {
  entries: [],
  loading: 'idle',
  loadingDelete: 'idle',
  loadingCreate: 'idle',
};

export const corridorsSlice = createSlice({
  initialState,
  name: 'corridors',
  reducers: {
    resetLoadingCorridors: (state) => {
      state.loading = 'idle';
    },
    resetLoadingDeleteCorridor: (state) => {
      state.loadingDelete = 'idle';
    },
    resetLoadingCreateCorridor: (state) => {
      state.loadingCreate = 'idle';
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchCorridors.fulfilled, (state, action: PayloadAction<CorridorsResponse>) => {
      state.entries = action.payload.map((geojson) => ({
        id: geojson.features[0]?.properties?.corridorId,
        name: geojson.features[0]?.properties?.corridorName,
        geojson,
      }));
      state.loading = 'loaded';
    });
    addCase(handleDeleteCorridor.fulfilled, (state) => {
      state.loadingDelete = 'loaded';
    });
    addCase(handleCreateCorridor.fulfilled, (state) => {
      state.loadingCreate = 'loaded';
    });
  },
});

export const selectCorridors = ({ corridors }: RootState) => corridors.entries;
export const selectLoadingCorridors = ({ corridors }: RootState) => corridors.loading;
export const selectLoadingDeleteCorridor = ({ corridors }: RootState) => corridors.loadingDelete;
export const selectLoadingCreateCorridor = ({ corridors }: RootState) => corridors.loadingCreate;

export const { resetLoadingCorridors, resetLoadingDeleteCorridor, resetLoadingCreateCorridor } = corridorsSlice.actions;
