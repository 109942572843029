import React, { PropsWithChildren } from 'react';
import { render, RenderOptions } from '@testing-library/react';
import { MemoryRouter } from 'react-router-dom';
import configureStore from 'redux-mock-store';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { cameraMock, loadingMock, userMock } from 'mocks';
import { RootState } from 'store';

const mockState = {
  user: userMock,
  camera: cameraMock,
  loading: loadingMock,
};

const createMockStore = configureStore([thunk]);

let store = createMockStore(mockState);

const Providers = ({ children }: PropsWithChildren<unknown>) => (
  <Provider store={store}>
    <MemoryRouter initialEntries={['/']}>{children}</MemoryRouter>
  </Provider>
);

const customRender = (ui: React.ReactElement, options?: RenderOptions) =>
  render(ui, { wrapper: Providers, ...options });

export * from '@testing-library/react';

export { customRender as render };

export const mockStore = (newState: RootState) => {
  store = createMockStore(newState);
};
export const mockSlice = (sliceName: string, newState: RootState) => {
  store = createMockStore({ ...mockState, [sliceName]: newState });
};
export const resetMockStore = () => {
  store = createMockStore(mockState);
};
export const getStore = () => store;
export const flushPromises = () => new Promise(setImmediate);
export const loadFailureSrc = 'loadFailureSrc';
