import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import {
  fetchSegments,
  selectLoadingSegments,
  resetLoadingSegments,
  selectSegments,
  setSelectedSegments,
  setSelectedSegmentsMaxSpeed,
  selectLoadingCreateApproach,
  selectLoadingCreateSegment,
  resetLoadingCreateSegment,
  selectLoadingDeleteSegment,
  resetLoadingDeleteSegment,
  setContextSelectedSegment,
} from 'features';
import { MapUtils } from 'components/EditGrid/utils';
import { LayerName } from 'components/EditGrid/types';

export const useSegments = (map: MapUtils | undefined) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const loadingSegments = useSelector(selectLoadingSegments);
  const segmentsGeoJSON = useSelector(selectSegments);
  const loadingCreateSegment = useSelector(selectLoadingCreateSegment);
  const loadingCreateApproach = useSelector(selectLoadingCreateApproach);
  const loadingDeleteSegment = useSelector(selectLoadingDeleteSegment);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */
  const selection = map?.selectedFeatures[LayerName.SEGMENTS];

  /* -------------------------------- Functions ------------------------------- */
  const handleSegmentsLoaded = () => {
    map?.setLayer({ name: LayerName.SEGMENTS, geojson: segmentsGeoJSON });
  };

  const setLayerVisible = () => {
    map?.getLayer(LayerName.SEGMENTS).setVisible(true);
  };

  const dispatchSelectedData = () => {
    if (!selection) return;
    const features = selection.getArray();
    const ids = features.map((feature) => feature.getId()) as string[];
    const maxSpeed = Math.max(...features.map((feature) => feature.get('speed'))).toString();
    dispatch(setSelectedSegments(ids));
    dispatch(setSelectedSegmentsMaxSpeed(maxSpeed));
  };

  const dispatchContextClickData = (layer: VectorLayer, feature: Feature) => {
    if (layer?.get('name') === LayerName.SEGMENTS && feature) {
      const id = feature.getId() as string;
      if (id) dispatch(setContextSelectedSegment(id));
    } else {
      dispatch(setContextSelectedSegment(null));
    }
  };

  const initEvents = () => {
    selection?.on('change', dispatchSelectedData);
    map?.on('contextmenu', dispatchContextClickData);
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (map) {
      dispatch(fetchSegments());
      initEvents();
    }
  }, [map]);

  useEffect(() => {
    if (loadingSegments === 'loaded') {
      dispatch(resetLoadingSegments());
      handleSegmentsLoaded();
    }
  }, [loadingSegments]);

  useEffect(() => {
    if (loadingCreateSegment === 'loaded') {
      dispatch(resetLoadingCreateSegment());
      dispatch(fetchSegments());
      setLayerVisible();
    }
  }, [loadingCreateSegment]);

  useEffect(() => {
    if (loadingDeleteSegment === 'loaded') {
      dispatch(resetLoadingDeleteSegment());
      dispatch(fetchSegments());
    }
  }, [loadingDeleteSegment]);

  useEffect(() => {
    if (loadingCreateApproach === 'loaded') {
      selection?.clear();
    }
  }, [loadingCreateApproach]);
};
