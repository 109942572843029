import { useSelector, useDispatch } from 'react-redux';
import { DialogName } from 'enums';
import { TextField } from '@material-ui/core';
import { selectDrawnGeofenceArea, createGeofenceArea } from 'features';
import { MainDialog } from 'components/EditGrid/components/Dialogs/MainDialog';

export const CreateGeofenceArea = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const coords = useSelector(selectDrawnGeofenceArea);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const onSubmit = ({ currentTarget }: React.FormEvent<HTMLFormElement>) =>
    dispatch(
      createGeofenceArea({
        geometry: JSON.parse(currentTarget.coords.value),
      }),
    );

  /* --------------------------------- Effects -------------------------------- */

  return (
    <MainDialog name={DialogName.CREATE_GEOFENCE_AREA} onSubmit={onSubmit} width="xs">
      <TextField
        name="coords"
        label="Coordinates"
        defaultValue={JSON.stringify(coords)}
        required
        multiline
        rowsMax={20}
      />
    </MainDialog>
  );
};
