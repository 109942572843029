import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTimeIntervals, postTimeIntervals } from 'api';
import { EditTimeIntervalsRequest } from 'interfaces';

export const fetchTimeIntervals = createAsyncThunk('timeIntervals/fetch', async () => {
  const { data } = await getTimeIntervals();
  return data;
});

export const editTimeIntervals = createAsyncThunk(
  'timeIntervals/edit',
  async (requestData: EditTimeIntervalsRequest) => {
    const { data } = await postTimeIntervals(requestData);
    return data;
  },
);
