import { getMeta } from 'utils/getMetaData';

const metaApiBaseUrl = getMeta('backend');
const metaLoginURL = getMeta('login');
const metaLogOutURL = getMeta('logout');

export const LOG_IN = 'oauth2/authorization/azure';
export const API_BASE_URL =
  metaApiBaseUrl && !metaApiBaseUrl.includes('CLUSTER_BACKEND') ? metaApiBaseUrl : process.env.REACT_APP_API_BASE_URL;
export const LOG_IN_URL =
  metaLoginURL && !metaLoginURL.includes('CLUSTER_LOGIN') ? metaLoginURL : `${API_BASE_URL}/${LOG_IN}`;
export const LOG_OUT_URL =
  metaLogOutURL && !metaLogOutURL.includes('CLUSTER_LOGOUT')
    ? metaLogOutURL
    : `https://login.windows.net/common/oauth2/logout?post_logout_redirect_uri=${API_BASE_URL}/logout`;

export const PRINCIPAL = 'principal';
export const USER = 'user';
export const USER_INFO = 'user/info';
export const ADMIN_TENANT = 'admin/tenant';
export const ADMIN_TENANTS = 'admin/tenants';
export const ADMIN_CAMERAS = 'admin/cameras';
export const ADMIN_SCHEDULER = 'admin/scheduler';
export const ADMIN_SCHEDULERS = 'admin/schedulers';
export const REGISTER = 'register';
export const DEREGISTER = 'deregister';
export const JOBS = 'jobs';
export const INTERSECTIONS = 'intersections';
export const ADD_INTERSECTION = 'admin/mapping/addintersection';
export const ADMIN_INTERSECTION = 'admin/mapping/intersection';
export const APPROACHES = 'approaches';
export const CREATE_APPROACH = 'admin/mapping/addapproach';
export const ADMIN_APPROACH = 'admin/mapping/approach';
export const ADD_SEGMENT = 'admin/mapping/addsegment';
export const ADMIN_SEGMENTS = 'admin/mapping/segments';
export const ADMIN_SEGMENT = '/admin/mapping/segment';
export const BUS_STOPS = 'busstops';
export const OSM_BUS_STOPS = 'admin/mapping/osmbusstops';
export const EDIT_BUS_STOP = 'admin/mapping/editbusstop';
export const ADMIN_BUS_STOP = 'admin/mapping/busstop';
export const OSMEXPORT = 'admin/mapping/osmexport';
export const GEOFENCEAREA = 'admin/mapping/geofence';
export const CORRIDORS = 'corridors';
export const ADMIN_CORRIDOR = 'admin/mapping/corridor';
export const TIME_INTERVALS = 'settings/timeintervals';
export const DIRECTIONS = 'admin/mapping/directions';
export const INTERSECTION_AREA = 'admin/mapping/intersectionarea';
export const BACKUP = 'admin/mapping/backup';
export const RESTORE = 'admin/mapping/restore';
