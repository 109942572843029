import { GeoJSON } from 'geojson';
import { Feature } from 'ol';
import { StyleFunction } from 'ol/style/Style';
import { Coordinate } from 'ol/coordinate';
import LayerGroup from 'ol/layer/Group';
import Layer from 'ol/layer/Layer';
import BaseLayer from 'ol/layer/Base';
import { DefaultsOptions as ControlOptions } from 'ol/control';
import { DefaultsOptions as InteractionOptions } from 'ol/interaction';
import Collection from 'ol/Collection';

export interface OlMapOptions {
  view?: ViewOptions;
  controls?: Partial<ControlOptions>;
  interactions?: Partial<InteractionOptions>;
  targetId?: string;
}

export interface MapUtilsOptions extends OlMapOptions {
  styleFunctions?: { [key: string]: StyleFunction };
  layers?: { [key: string]: LayerOptions };
  tiles?: TilesOptions;
}

export interface ViewOptions {
  coords?: Coordinate;
  zoom?: number;
  maxZoom?: number;
}

export interface LayerOptions {
  visible?: boolean;
  zIndex?: number;
  group?: string;
  cluster?: boolean;
  distance?: number;
  selectable?: boolean;
  style?: StyleFunction;
  styleSelected?: StyleFunction;
  zoomOnClusterClick?: boolean;
  dragBox?: boolean;
  centerOnCreate?: boolean;
  hasHoverState?: boolean;
}

export type AllLayersOptions = {
  [key: string]: LayerOptions & Required<Omit<LayerOptions, 'style' | 'styleSelected'>>;
};

export interface LayerInitOptions extends LayerOptions {
  name: string;
  as?: string;
  geojson?: GeoJSON;
}

export type TilesOptions = { [key: string]: TileOptions };

export type GetLayerOptions = {
  condition?: Function;
  group?: LayerGroup | BaseLayer | string;
  deepSearch?: boolean;
  findOne?: boolean;
};

export interface TileOptions {
  maxZoom?: number;
  url?: string;
}

export enum TileName {
  OSM = 'OSM',
  MS_ATLAS = 'MS_ATLAS',
}

export interface FitExtentOptions {
  duration: number;
  padding: [number, number, number, number];
}

export type LastHoveredFeature = { feature: Feature; layer: Layer } | null;

export type MapEventsCallbacks = { [key: string]: Function[] };

export type SelectedFeatures = { [key: string]: Collection<Feature> };

export type LayersWithHoverCursor = BaseLayer[];
