import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toLonLat } from 'ol/proj';
import { Menu, MenuItem, Divider } from '@material-ui/core';
import {
  selectContextClickLonLat,
  selectContextClickPixels,
  setContextClickLonLat,
  setContextClickPixels,
  setActiveDialog,
  selectContextSelectedCorridor,
  selectContextSelectedApproach,
  selectSelectedApproaches,
  selectSelectedSegments,
  selectContextSelectedBusStop,
  selectContextSelectedIntersection,
  selectContextSelectedSegment,
} from 'features';
import { DialogName } from 'enums';
import { useRerenderFromMap } from 'components/EditGrid/hooks';
import { LayerName } from 'components/EditGrid/types';
import { MapUtils } from 'components/EditGrid/utils/mapUtils';
import styles from './ContextMenu.module.scss';

interface ContextMenuProps {
  map: MapUtils;
  geofenceArea: {
    draw: Function;
  };
  detectionArea: {
    draw: Function;
    show: Function;
  };
}

export const ContextMenu: React.FC<ContextMenuProps> = ({ map, geofenceArea, detectionArea }) => {
  /* ---------------------------------- Hooks --------------------------------- */
  useRerenderFromMap(map);
  const anchorElRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const contextClickLonLat = useSelector(selectContextClickLonLat);
  const contextClickPixels = useSelector(selectContextClickPixels);
  const contextSelectedCorridor = useSelector(selectContextSelectedCorridor);
  const contextSelectedApproach = useSelector(selectContextSelectedApproach);
  const selectedApproaches = useSelector(selectSelectedApproaches);
  const selectedSegments = useSelector(selectSelectedSegments);
  const contextSelectedBusStop = useSelector(selectContextSelectedBusStop);
  const contextSelectedIntersection = useSelector(selectContextSelectedIntersection);
  const contextSelectedSegment = useSelector(selectContextSelectedSegment);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */
  const pointLayer = map?.getLayer(LayerName.SELECTED_POINT);
  const isPointVisible = !!pointLayer?.getVisible();
  const mapRect = map?.olMap.getTargetElement().getBoundingClientRect();

  /* -------------------------------- Functions ------------------------------- */
  const dispatchContextClickCoords = (event: MouseEvent) => {
    if (!map) return;
    dispatch(setContextClickPixels([event.clientX, event.clientY]));
    dispatch(setContextClickLonLat(toLonLat(map.olMap.getEventCoordinate(event))));
  };

  const showClickPoint = () => {
    if (!map) return;
    const layer = map.setLayer({
      name: LayerName.SELECTED_POINT,
      geojson: { type: 'Point', coordinates: contextClickLonLat },
    });
    layer?.setVisible(true);
    document.body.style.cursor = 'default';
  };

  const hideClickPoint = () => {
    pointLayer?.setVisible(false);
  };

  const onMenuClick = () => {
    hideClickPoint();
  };

  const onMenuClose = () => {
    hideClickPoint();
  };

  const onContextClick = (event: MouseEvent) => {
    dispatchContextClickCoords(event);
    event.preventDefault();
  };

  const initEvents = () => {
    map?.olMap.getTargetElement().addEventListener('contextmenu', onContextClick, true);
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (map) {
      initEvents();
    }
  }, [map]);

  useEffect(() => {
    if (contextClickLonLat.length) {
      showClickPoint();
    }
  }, [contextClickLonLat]);

  if (!mapRect) return null;

  return (
    <>
      <div
        className={styles.clickPoint}
        ref={anchorElRef}
        style={{
          left: `${contextClickPixels[0] - mapRect?.x + 10}px`,
          top: `${contextClickPixels[1] - mapRect?.y + 30}px`,
        }}
      />
      <Menu open={isPointVisible} onClose={onMenuClose} onClick={onMenuClick} anchorEl={anchorElRef.current}>
        <MenuItem onClick={() => dispatch(setActiveDialog(DialogName.CREATE_INTERSECTION))}>
          {DialogName.CREATE_INTERSECTION}
        </MenuItem>
        <MenuItem onClick={() => dispatch(setActiveDialog(DialogName.CREATE_SEGMENT))}>
          {DialogName.CREATE_SEGMENT}
        </MenuItem>
        {!!selectedSegments.length && (
          <MenuItem onClick={() => dispatch(setActiveDialog(DialogName.CREATE_APPROACH))}>
            {DialogName.CREATE_APPROACH}
          </MenuItem>
        )}
        {contextSelectedIntersection && (
          <div>
            <MenuItem onClick={() => detectionArea.show()}>Show Detection Area</MenuItem>
            <MenuItem onClick={() => detectionArea.draw()}>{DialogName.CREATE_DETECTION_AREA}</MenuItem>
          </div>
        )}
        <MenuItem onClick={() => geofenceArea.draw()}>{DialogName.CREATE_GEOFENCE_AREA}</MenuItem>
        {contextSelectedBusStop && (
          <MenuItem onClick={() => dispatch(setActiveDialog(DialogName.EDIT_BUSSTOP))}>
            {DialogName.EDIT_BUSSTOP}
          </MenuItem>
        )}
        {!!selectedApproaches.length && (
          <MenuItem onClick={() => dispatch(setActiveDialog(DialogName.CREATE_CORRIDOR))}>
            {DialogName.CREATE_CORRIDOR}
          </MenuItem>
        )}
        {(contextSelectedApproach ||
          contextSelectedIntersection ||
          contextSelectedSegment ||
          contextSelectedBusStop ||
          contextSelectedCorridor) && <Divider />}
        {contextSelectedApproach && (
          <MenuItem onClick={() => dispatch(setActiveDialog(DialogName.DELETE_APPROACH))}>
            {DialogName.DELETE_APPROACH}
          </MenuItem>
        )}
        {contextSelectedIntersection && (
          <MenuItem onClick={() => dispatch(setActiveDialog(DialogName.DELETE_INTERSECTION))}>
            {DialogName.DELETE_INTERSECTION}
          </MenuItem>
        )}
        {contextSelectedSegment && (
          <MenuItem onClick={() => dispatch(setActiveDialog(DialogName.DELETE_SEGMENT))}>
            {DialogName.DELETE_SEGMENT}
          </MenuItem>
        )}
        {contextSelectedBusStop && (
          <MenuItem onClick={() => dispatch(setActiveDialog(DialogName.DELETE_BUSSTOP))}>
            {DialogName.DELETE_BUSSTOP}
          </MenuItem>
        )}
        {contextSelectedCorridor && (
          <MenuItem onClick={() => dispatch(setActiveDialog(DialogName.DELETE_CORRIDOR))}>
            {DialogName.DELETE_CORRIDOR}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
