import { TileName, ViewOptions, FitExtentOptions, LayerOptions } from './types';

export const MAIN_GROUP_NAME = 'main-group';
export const DEFAULT_TARGET_ID = 'olmap';
export const DEFAULT_TILE_NAME = TileName.OSM;

export const olProp = {
  LAYER_NAME: 'name',
  PARENT_GROUP: 'group',
  REVISION: 'revision',
};

export const defaultViewOptions: Required<ViewOptions> = {
  zoom: 13,
  maxZoom: 30,
  coords: [86.922623, 27.986065],
};

export const defaultLayersOptions: Required<Omit<LayerOptions, 'style' | 'styleSelected'>> = {
  visible: true,
  zIndex: 1,
  group: MAIN_GROUP_NAME,
  cluster: false,
  distance: 40,
  selectable: false,
  zoomOnClusterClick: false,
  dragBox: false,
  centerOnCreate: false,
  hasHoverState: false,
};

export const centerOnLayerOptions: FitExtentOptions = {
  duration: 1000,
  padding: [20, 20, 20, 20],
};

export const centerOnFeaturesOptions: FitExtentOptions = {
  duration: 1000,
  padding: [400, 400, 400, 400],
};

export const defaultInteractionsOptions = {
  pinchRotate: true,
  altShiftDragRotate: false,
};

export const defaultControlsOptions = {
  attribution: false,
  zoom: false,
};

const subscriptionKey = '9BZL87dlWB2JkUimFSbL0EO7FjuLmyDlm4YBIffOcTc';

export const defaultTilesOptions = {
  [TileName.OSM]: {
    url: 'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png',
    maxZoom: 19,
  },
  [TileName.MS_ATLAS]: {
    url: `https://atlas.microsoft.com/map/tile?subscription-key=${subscriptionKey}&api-version=2.0&tilesetId=microsoft.base.road&zoom={z}&x={x}&y={y}&tileSize=512&language=en-US`,
    maxZoom: 19,
  },
};
