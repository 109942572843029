import { createAsyncThunk } from '@reduxjs/toolkit';
import { runJobsPerCamera } from 'api';
import { StatusCodes } from 'enums';
import { RunJobsThunkProps, RejectedValue } from 'interfaces';

export const runJobs = createAsyncThunk<undefined, RunJobsThunkProps, { rejectValue: RejectedValue }>(
  'jobs/runJobs',
  async ({ cameraId, type, startDate, endDate }, thunkAPI) => {
    const jobsRequest = await runJobsPerCamera(cameraId, type, startDate, endDate);

    if (jobsRequest.status && jobsRequest.status !== StatusCodes.OK) {
      return thunkAPI.rejectWithValue({ error: jobsRequest.statusText });
    }

    return undefined;
  },
);
