import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';
import { DialogName } from 'enums';
import { selectSelectedSegments, selectSelectedSegmentsMaxSpeed, handleCreateApproach } from 'features';
import { MainDialog } from 'components/EditGrid/components/Dialogs/MainDialog';

export const CreateApproach = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const segmentIds = useSelector(selectSelectedSegments);
  const maxSpeed = useSelector(selectSelectedSegmentsMaxSpeed);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const onSubmit = ({ currentTarget }: React.FormEvent<HTMLFormElement>) =>
    dispatch(
      handleCreateApproach({
        segmentIds,
        speed: currentTarget.speed.value,
      }),
    );

  /* --------------------------------- Effects -------------------------------- */

  return (
    <MainDialog name={DialogName.CREATE_APPROACH} onSubmit={onSubmit}>
      <TextField
        label="Segments Ids"
        defaultValue={segmentIds?.join(', ')}
        inputProps={{ readOnly: true }}
        multiline
        required
        style={{ gridColumn: '1/-1' }}
      />
      <TextField name="speed" label="Speed (km/h)" defaultValue={maxSpeed} type="number" required />
    </MainDialog>
  );
};
