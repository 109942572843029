import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { IntersectionsResponse, IntersectionsSliceState } from 'interfaces';
import { fetchIntersections, handleCreateIntersection, handleDeleteIntersection } from './thunks';

const initialState: IntersectionsSliceState = {
  geojson: {
    type: 'FeatureCollection',
    features: [],
  },
  loading: 'idle',
  loadingCreate: 'idle',
  loadingDelete: 'idle',
};

export const intersectionsSlice = createSlice({
  initialState,
  name: 'intersections',
  reducers: {
    resetLoadingIntersections: (state) => {
      state.loading = 'idle';
    },
    resetLoadingCreateIntersection: (state) => {
      state.loadingCreate = 'idle';
    },
    resetLoadingDeleteIntersection: (state) => {
      state.loadingDelete = 'idle';
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchIntersections.fulfilled, (state, action: PayloadAction<IntersectionsResponse>) => {
      state.geojson = action.payload;
      state.loading = 'loaded';
    });
    addCase(handleCreateIntersection.fulfilled, (state) => {
      state.loadingCreate = 'loaded';
    });
    addCase(handleDeleteIntersection.fulfilled, (state) => {
      state.loadingDelete = 'loaded';
    });
  },
});

export const selectIntersections = ({ intersections }: RootState) => intersections.geojson;
export const selectLoadingIntersections = ({ intersections }: RootState) => intersections.loading;
export const selectLoadingCreateIntersection = ({ intersections }: RootState) => intersections.loadingCreate;
export const selectLoadingDeleteIntersection = ({ intersections }: RootState) => intersections.loadingDelete;

export const {
  resetLoadingIntersections,
  resetLoadingCreateIntersection,
  resetLoadingDeleteIntersection,
} = intersectionsSlice.actions;
