import { Polygon } from 'geojson';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { GeofenceAreaResponse, GeofenceAreaSliceState } from 'interfaces';
import { LoadingState } from 'types';
import { fetchGeofenceArea, createGeofenceArea } from './thunks';

const initialState: GeofenceAreaSliceState = {
  geojson: {
    type: 'Polygon',
    coordinates: [],
  },
  loading: 'idle',
  loadingAdd: 'idle',
};

export const geofenceAreaSlice = createSlice({
  initialState,
  name: 'geofenceArea',
  reducers: {
    resetLoadingGeofenceArea: (state) => {
      state.loading = 'idle';
    },
    resetLoadingCreateGeofenceArea: (state) => {
      state.loadingAdd = 'idle';
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchGeofenceArea.fulfilled, (state, action: PayloadAction<GeofenceAreaResponse>) => {
      if (action.payload.geometry.length) {
        state.geojson.coordinates = [action.payload.geometry];
      } else {
        state.geojson.coordinates = [];
      }
      state.loading = 'loaded';
    });
    addCase(createGeofenceArea.fulfilled, (state) => {
      state.loadingAdd = 'loaded';
    });
  },
});

export const selectGeofenceArea = ({ geofenceArea }: RootState): Polygon => geofenceArea.geojson;
export const selectLoadingGeofenceArea = ({ geofenceArea }: RootState): LoadingState => geofenceArea.loading;
export const selectLoadingCreateGeofenceArea = ({ geofenceArea }: RootState): LoadingState => geofenceArea.loadingAdd;

export const { resetLoadingGeofenceArea, resetLoadingCreateGeofenceArea } = geofenceAreaSlice.actions;
