import { TenantId } from 'enums';

export const tenants: { [key: string]: { name: string } } = {
  [TenantId.AXILION_TEST]: {
    name: 'Test organization (axilionTEST.onmicrosoft.com)',
  },
  [TenantId.AXILION_QA]: {
    name: 'QA Tenant',
  },
  [TenantId.AXILION_DEV]: {
    name: 'Axilion (axilionDEV.onmicrosoft.com)',
  },
  [TenantId.AXILION_CSP]: {
    name: 'Tel Aviv Demo (axilionCSP.onmicrosoft.com)',
  },
  [TenantId.TEL_AVIV]: {
    name: 'Tel Aviv Municipality (Client tenant)',
  },
  [TenantId.JERUSALEM]: {
    name: 'Jerusalem',
  },
  [TenantId.NEW_YORK]: {
    name: 'New York',
  },
  [TenantId.BOSTON]: {
    name: 'Boston',
  },
  [TenantId.DUBAI]: {
    name: 'Dubai',
  },
  [TenantId.BOLZANO_STAGE]: {
    name: 'Bolzano (Stage)',
  },
  [TenantId.BOLZANO_CLIENT]: {
    name: 'Bolzano (Client Tenant)',
  },
};
