import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';
import { DialogName } from 'enums';
import { selectContextSelectedBusStop, handleEditBusStop } from 'features';
import { MainDialog } from 'components/EditGrid/components/Dialogs/MainDialog';

export const EditBusStop = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const busStop = useSelector(selectContextSelectedBusStop);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const onSubmit = ({ currentTarget }: React.FormEvent<HTMLFormElement>) =>
    dispatch(
      handleEditBusStop({
        id: currentTarget.busStopId.value,
        name: currentTarget.busStopName.value,
        currentId: currentTarget.currentId.value,
      }),
    );

  /* --------------------------------- Effects -------------------------------- */

  return (
    <MainDialog name={DialogName.EDIT_BUSSTOP} onSubmit={onSubmit}>
      <TextField name="busStopId" label="ID" defaultValue={busStop?.id} InputProps={{ readOnly: true }} required />
      <TextField name="busStopName" label="Name" defaultValue={busStop?.name} required />
      <TextField name="currentId" label="Current ID" defaultValue={busStop?.currentId} required />
    </MainDialog>
  );
};
