import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Interaction } from 'ol/interaction';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import GeometryType from 'ol/geom/GeometryType';
import VectorLayer from 'ol/layer/Vector';
import { toLonLat } from 'ol/proj';
import { Coordinate } from 'ol/coordinate';
import { Feature } from 'ol';
import {
  fetchGeofenceArea,
  selectLoadingGeofenceArea,
  selectLoadingCreateGeofenceArea,
  resetLoadingCreateGeofenceArea,
  resetLoadingGeofenceArea,
  selectGeofenceArea,
  setActiveDialog,
  setDrawnGeofenceArea,
} from 'features';
import { DialogName } from 'enums';
import { LayerName } from 'components/EditGrid/types';
import { MapUtils } from 'components/EditGrid/utils';

export const useGeofenceArea = (map: MapUtils | undefined) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const drawRef = useRef<Interaction | null>(null);
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const loadingGeofenceArea = useSelector(selectLoadingGeofenceArea);
  const geofenceAreaGeoJSON = useSelector(selectGeofenceArea);
  const loadingCreateGeofenceArea = useSelector(selectLoadingCreateGeofenceArea);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const removeDraw = () => {
    if (drawRef.current) map?.olMap.removeInteraction(drawRef.current);
    map?.getLayer(LayerName.GEOFENCE_AREA_DRAWING).setVisible(false);
  };

  const setLayerVibile = () => {
    map?.getLayer(LayerName.GEOFENCE_AREA).setVisible(true);
  };

  const handleGeofenceLoaded = () => {
    map?.setLayer({ name: LayerName.GEOFENCE_AREA, geojson: geofenceAreaGeoJSON });
    removeDraw();
  };

  const dispatchDrawnData = (feature: Feature) => {
    const transformedCoords: Coordinate[] = [];
    feature
      .getProperties()
      .geometry.getCoordinates()[0]
      .forEach((coords: Coordinate) => transformedCoords.push(toLonLat(coords)));
    dispatch(setDrawnGeofenceArea(transformedCoords));
  };

  const draw = () => {
    if (!drawRef.current || !map) return;
    map.getLayer(LayerName.GEOFENCE_AREA).setVisible(false);
    const drawingLayer = map?.getLayer(LayerName.GEOFENCE_AREA_DRAWING) as VectorLayer;
    drawingLayer?.setVisible(true);
    drawingLayer.getSource().clear();
    map.olMap.addInteraction(drawRef.current);
    drawRef.current.on('drawend', (event: DrawEvent) => {
      dispatchDrawnData(event.feature);
      dispatch(setActiveDialog(DialogName.CREATE_GEOFENCE_AREA));
      removeDraw();
    });
  };

  const initDraw = () => {
    const type = 'Polygon' as GeometryType;
    const layer = map?.setLayer({ name: LayerName.GEOFENCE_AREA_DRAWING, as: LayerName.GEOFENCE_AREA });
    const source = layer?.getSource();
    drawRef.current = new Draw({ type, source });
  };

  const initEvents = () => {
    window.addEventListener('keydown', ({ key }: KeyboardEvent) => key === 'Escape' && removeDraw());
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (map) {
      dispatch(fetchGeofenceArea());
      initDraw();
      initEvents();
    }
  }, [map]);

  useEffect(() => {
    if (loadingGeofenceArea === 'loaded') {
      dispatch(resetLoadingGeofenceArea());
      handleGeofenceLoaded();
    }
  }, [loadingGeofenceArea]);

  useEffect(() => {
    if (loadingCreateGeofenceArea === 'loaded') {
      dispatch(resetLoadingCreateGeofenceArea());
      dispatch(fetchGeofenceArea());
      setLayerVibile();
    }
  }, [loadingCreateGeofenceArea]);

  return {
    draw,
  };
};
