import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchBusStops,
  selectLoadingBusStops,
  resetLoadingBusStops,
  selectBusStops,
  selectLoadingOsmBusStops,
  resetLoadingOsmBusStops,
  selectLoadingEditBusStop,
  selectLoadingDeleteBusStop,
  resetLoadingDeleteBusStop,
  resetLoadingEditBusStop,
  setContextSelectedBusStop,
} from 'features';
import VectorLayer from 'ol/layer/Vector';
import { Feature } from 'ol';
import { MapUtils } from 'components/EditGrid/utils';
import { LayerName } from 'components/EditGrid/types';

export const useBusStops = (map: MapUtils | undefined) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const loadingBusStops = useSelector(selectLoadingBusStops);
  const loadingOsmBusStops = useSelector(selectLoadingOsmBusStops);
  const busStopsGeoJSON = useSelector(selectBusStops);
  const loadingEditBusStop = useSelector(selectLoadingEditBusStop);
  const loadingDeleteBusStop = useSelector(selectLoadingDeleteBusStop);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const handleBusStopsLoaded = () => {
    map?.setLayer({ name: LayerName.BUS_STOPS, geojson: busStopsGeoJSON });
  };

  const setLayerVisible = () => {
    map?.getLayer(LayerName.BUS_STOPS).setVisible(true);
  };

  const dispatchContextClickData = (layer: VectorLayer, feature: Feature) => {
    if (layer?.get('name') === LayerName.BUS_STOPS && feature) {
      const busStop = {
        id: feature.get('id'),
        name: feature.get('name'),
        currentId: feature.get('id'),
      };
      dispatch(setContextSelectedBusStop(busStop));
    } else {
      dispatch(setContextSelectedBusStop(null));
    }
  };

  const initEvents = () => {
    map?.on('contextmenu', dispatchContextClickData);
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (map) {
      dispatch(fetchBusStops());
      initEvents();
    }
  }, [map]);

  useEffect(() => {
    if (loadingBusStops === 'loaded') {
      dispatch(resetLoadingBusStops());
      handleBusStopsLoaded();
    }
  }, [loadingBusStops]);

  useEffect(() => {
    if (loadingOsmBusStops === 'loaded') {
      dispatch(resetLoadingOsmBusStops());
      dispatch(fetchBusStops());
      setLayerVisible();
    }
  }, [loadingOsmBusStops]);

  useEffect(() => {
    if (loadingEditBusStop === 'loaded') {
      dispatch(resetLoadingEditBusStop());
      dispatch(fetchBusStops());
    }
  }, [loadingEditBusStop]);

  useEffect(() => {
    if (loadingDeleteBusStop === 'loaded') {
      dispatch(resetLoadingDeleteBusStop());
      dispatch(fetchBusStops());
    }
  }, [loadingDeleteBusStop]);
};
