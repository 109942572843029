import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { BusStopsResponse, BusStopsSliceState } from 'interfaces';
import { fetchBusStops, handleEditBusStop, fetchOsmBusStops, handleDeleteBusStop } from './thunks';

const initialState: BusStopsSliceState = {
  geojson: {
    type: 'FeatureCollection',
    features: [],
  },
  loading: 'idle',
  loadingEdit: 'idle',
  loadingDelete: 'idle',
  loadingOsm: 'idle',
};

export const busStopsSlice = createSlice({
  initialState,
  name: 'busStops',
  reducers: {
    resetLoadingBusStops: (state) => {
      state.loading = 'idle';
    },
    resetLoadingEditBusStop: (state) => {
      state.loadingEdit = 'idle';
    },
    resetLoadingDeleteBusStop: (state) => {
      state.loadingDelete = 'idle';
    },
    resetLoadingOsmBusStops: (state) => {
      state.loadingOsm = 'idle';
    },
  },
  extraReducers: ({ addCase }) => {
    addCase(fetchBusStops.fulfilled, (state, action: PayloadAction<BusStopsResponse>) => {
      state.geojson = action.payload;
      state.loading = 'loaded';
    });
    addCase(handleEditBusStop.fulfilled, (state) => {
      state.loadingEdit = 'loaded';
    });
    addCase(handleDeleteBusStop.fulfilled, (state) => {
      state.loadingDelete = 'loaded';
    });
    addCase(fetchOsmBusStops.fulfilled, (state) => {
      state.loadingOsm = 'loaded';
    });
  },
});

export const selectBusStops = ({ busStops }: RootState) => busStops.geojson;
export const selectLoadingBusStops = ({ busStops }: RootState) => busStops.loading;
export const selectLoadingEditBusStop = ({ busStops }: RootState) => busStops.loadingEdit;
export const selectLoadingDeleteBusStop = ({ busStops }: RootState) => busStops.loadingDelete;
export const selectLoadingOsmBusStops = ({ busStops }: RootState) => busStops.loadingOsm;

export const {
  resetLoadingBusStops,
  resetLoadingOsmBusStops,
  resetLoadingEditBusStop,
  resetLoadingDeleteBusStop,
} = busStopsSlice.actions;
