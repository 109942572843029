import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchTimeIntervals,
  selectLoadingTimeIntervals,
  resetLoadingTimeIntervals,
  selectLoadingEditTimeIntervals,
  resetLoadingEditTimeIntervals,
} from 'features';
import { MapUtils } from 'components/EditGrid/utils';

export const useTimeIntervals = (map: MapUtils | undefined) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const loadingTimeIntervals = useSelector(selectLoadingTimeIntervals);
  const loadingEditTimeIntervals = useSelector(selectLoadingEditTimeIntervals);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */

  /* --------------------------------- Effects -------------------------------- */

  useEffect(() => {
    if (map) {
      dispatch(fetchTimeIntervals());
    }
  }, [map]);

  useEffect(() => {
    if (loadingTimeIntervals === 'loaded') {
      dispatch(resetLoadingTimeIntervals());
    }
  }, [loadingTimeIntervals]);

  useEffect(() => {
    if (loadingEditTimeIntervals === 'loaded') {
      dispatch(resetLoadingEditTimeIntervals());
      dispatch(fetchTimeIntervals());
    }
  }, [loadingEditTimeIntervals]);
};
