import { useSelector, useDispatch } from 'react-redux';
import { DialogName } from 'enums';
import { selectContextSelectedSegment, handleDeleteSegment } from 'features';
import { MainDialog } from 'components/EditGrid/components/Dialogs/MainDialog';

export const DeleteSegment = () => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const id = useSelector(selectContextSelectedSegment);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */

  /* -------------------------------- Functions ------------------------------- */
  const onSubmit = () => {
    if (id) dispatch(handleDeleteSegment(id));
  };

  /* --------------------------------- Effects -------------------------------- */

  return (
    <MainDialog name={DialogName.DELETE_SEGMENT} onSubmit={onSubmit}>
      {`ID: ${id}`}
    </MainDialog>
  );
};
