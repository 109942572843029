import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Feature } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import {
  fetchApproaches,
  selectLoadingApproaches,
  resetLoadingApproaches,
  selectApproaches,
  selectLoadingDeleteApproach,
  selectLoadingCreateCorridor,
  setSelectedApproaches,
  selectLoadingCreateApproach,
  resetLoadingCreateApproach,
  resetLoadingDeleteApproach,
  setContextSelectedApproach,
} from 'features';

import { MapUtils } from 'components/EditGrid/utils';
import { LayerName } from 'components/EditGrid/types';

export const useApproaches = (map: MapUtils | undefined) => {
  /* ---------------------------------- Hooks --------------------------------- */
  const dispatch = useDispatch();

  /* -------------------------------- Selectors ------------------------------- */
  const loadingApproaches = useSelector(selectLoadingApproaches);
  const approachesGeoJSON = useSelector(selectApproaches);
  const loadingCreateApproach = useSelector(selectLoadingCreateApproach);
  const loadingDeleteApproach = useSelector(selectLoadingDeleteApproach);
  const loadingCreateCorridor = useSelector(selectLoadingCreateCorridor);

  /* ---------------------------------- State --------------------------------- */

  /* -------------------------------- Variables ------------------------------- */
  const selection = map?.selectedFeatures[LayerName.APPROACHES];

  /* -------------------------------- Functions ------------------------------- */
  const handleApproachesLoaded = () => {
    map?.setLayer({ name: LayerName.APPROACHES, geojson: approachesGeoJSON });
  };

  const setLayerVisible = () => {
    map?.getLayer(LayerName.APPROACHES).setVisible(true);
  };

  const dispatchSelectedData = () => {
    const ids = selection?.getArray().map((feature) => feature.getId()) as string[];
    dispatch(setSelectedApproaches(ids));
  };

  const dispatchContextClickData = (layer: VectorLayer, feature: Feature) => {
    if (layer?.get('name') === LayerName.APPROACHES && feature) {
      const id = feature.getId() as string;
      dispatch(setContextSelectedApproach(id));
    } else {
      dispatch(setContextSelectedApproach(null));
    }
  };

  const initEvents = () => {
    selection?.on('change', dispatchSelectedData);
    map?.on('contextmenu', dispatchContextClickData);
  };

  /* --------------------------------- Effects -------------------------------- */
  useEffect(() => {
    if (map) {
      dispatch(fetchApproaches());
      initEvents();
    }
  }, [map]);

  useEffect(() => {
    if (loadingApproaches === 'loaded') {
      dispatch(resetLoadingApproaches());
      handleApproachesLoaded();
    }
  }, [loadingApproaches]);

  useEffect(() => {
    if (loadingCreateApproach === 'loaded') {
      dispatch(resetLoadingCreateApproach());
      dispatch(fetchApproaches());
      setLayerVisible();
    }
  }, [loadingCreateApproach]);

  useEffect(() => {
    if (loadingDeleteApproach === 'loaded') {
      dispatch(resetLoadingDeleteApproach());
      dispatch(fetchApproaches());
    }
  }, [loadingDeleteApproach]);

  useEffect(() => {
    if (loadingCreateCorridor === 'loaded') {
      selection?.clear();
    }
  }, [loadingCreateCorridor]);
};
