import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDetectionArea, postDetectionArea } from 'api';
import { DetectionAreaRequest, CreateDetectionAreaRequest } from 'interfaces';

export const fetchDetectionArea = createAsyncThunk('detectionArea/fetch', async (requestData: DetectionAreaRequest) => {
  const { data } = await getDetectionArea(requestData);
  return data;
});

export const createDetectionArea = createAsyncThunk(
  'detectionArea/create',
  async (requestData: CreateDetectionAreaRequest) => {
    const { data } = await postDetectionArea(requestData);
    return data;
  },
);
