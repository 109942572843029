import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCameras, postRegisterCamera, postDeregisterCamera } from 'api';
import { StatusCodes } from 'enums';
import { RegisterCameraForm, DeregisterCamera, RejectedValue, Camera } from 'interfaces';

export const fetchCameras = createAsyncThunk<Array<Camera>, void, { rejectValue: RejectedValue }>(
  'camera/fetchCameras',
  async (_, thunkAPI) => {
    const camerasRes = await getCameras();

    if (camerasRes.status && camerasRes.status !== StatusCodes.OK) {
      return thunkAPI.rejectWithValue({ error: camerasRes.statusText });
    }

    return camerasRes.data;
  },
);

export const registerCamera = createAsyncThunk<undefined, RegisterCameraForm, { rejectValue: RejectedValue }>(
  'camera/registerCamera',
  async ({ cameraId, vehicleType, freeText, date }, thunkAPI) => {
    const registerCameraRes = await postRegisterCamera(cameraId, vehicleType, freeText, date);

    if (registerCameraRes.status && registerCameraRes.status !== StatusCodes.OK) {
      return thunkAPI.rejectWithValue({ error: registerCameraRes.statusText });
    }

    return undefined;
  },
);

export const deregisterCamera = createAsyncThunk<undefined, DeregisterCamera, { rejectValue: RejectedValue }>(
  'camera/deregisterCamera',
  async ({ cameraId, date, reason }, thunkAPI) => {
    const deregisterCameraRes = await postDeregisterCamera({ cameraId, date, reason });

    if (deregisterCameraRes.status && deregisterCameraRes.status !== StatusCodes.OK) {
      return thunkAPI.rejectWithValue({ error: deregisterCameraRes.statusText });
    }

    return undefined;
  },
);
