import React from 'react';
import { Card, Container, Typography } from '@material-ui/core';
import { PageProps } from 'interfaces';
import styles from './Page.module.scss';

export const Page: React.FC<PageProps> = ({ children, title, headerContent }) => {
  return (
    <div className={styles.page}>
      <Container>
        <div className={styles.header}>
          <Typography variant="h4">{title}</Typography>
          <div className={styles.headerContent}>{headerContent}</div>
        </div>
        <Card>{children}</Card>
      </Container>
    </div>
  );
};
