import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import store from 'store';
import DateFnsUtils from '@date-io/date-fns';
import { App } from './App';
import './index.css';

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <HashRouter>
        <App />
      </HashRouter>
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root'),
);
