import React, { ChangeEvent, useState, useEffect, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleCreateSchedule, handleUpdateSchedule, selectRegisteredCameraIds } from 'features';
import { ScheduleRequest, ScheduleDialogProps } from 'interfaces';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import styles from './ScheduleDialog.module.scss';

export const ScheduleDialog: React.FC<ScheduleDialogProps> = ({
  isDialogOpen,
  setIsDialogOpen,
  title,
  job = { id: '' },
  isEdit,
}) => {
  const dispatch = useDispatch();
  const registeredCameraIds = useSelector(selectRegisteredCameraIds);
  const [scheduleError, setScheduleError] = useState(false);
  const [jobId, setJobId] = useState('');
  const [jobIdError, setJobIdError] = useState(false);
  const [cameraIdsError, setCameraIdsError] = useState(false);
  const [schedule, setSchedule] = useState('30 3 * * *');
  const [cameraIds, setCameraIds] = useState('');
  const [useSpot, setUseSpot] = useState(true);

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSchedule('30 3 * * *');
    setCameraIds(JSON.stringify(registeredCameraIds));
    setUseSpot(true);
    setScheduleError(false);
    setCameraIdsError(false);
    setJobIdError(false);
  };

  const toggleUseSpot = () => setUseSpot((prev) => !prev);

  const updateSchedule = (event: ChangeEvent<{ value: string }>) => {
    setSchedule(event.target.value);
  };

  const updateJobId = (event: ChangeEvent<{ value: string }>) => {
    setJobId(event.target.value);
  };

  const updateCameraIds = (event: ChangeEvent<{ value: string }>) => {
    setCameraIds(event.target.value);
    try {
      JSON.parse(event.target.value);
      setCameraIdsError(false);
    } catch (e) {
      setCameraIdsError(true);
    }
  };

  const submitCreateSchedule = async (event: FormEvent) => {
    event.preventDefault();

    if (scheduleError || cameraIdsError || jobIdError) return;

    if (!schedule || !cameraIds || !jobId) {
      setScheduleError(!schedule);
      setCameraIdsError(!cameraIds);
      setJobIdError(!jobId);
    } else {
      const requestBody: ScheduleRequest = {
        cameraId: JSON.parse(cameraIds),
        schedule,
        useSpot,
      };
      if (isEdit) {
        dispatch(handleUpdateSchedule({ requestBody, jobId }));
      } else {
        dispatch(handleCreateSchedule({ requestBody, jobId }));
      }

      closeDialog();
    }
  };

  useEffect(() => {
    if (!registeredCameraIds) return;
    setCameraIds(JSON.stringify(registeredCameraIds));
  }, [registeredCameraIds]);

  useEffect(() => {
    if (job) {
      setJobId(job.id);
    }
  }, [job?.id]);

  return (
    <>
      <Dialog open={isDialogOpen} onClose={closeDialog} fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <form onSubmit={submitCreateSchedule}>
          <DialogContent className={styles.dialogContent}>
            <TextField
              autoFocus
              label="Job ID"
              error={jobIdError}
              value={jobId}
              onChange={updateJobId}
              disabled={isEdit}
            />
            <TextField label="Schedule" error={scheduleError} value={schedule} onChange={updateSchedule} />
            <TextField
              multiline
              label="Camera IDs"
              error={cameraIdsError}
              value={cameraIds}
              onChange={updateCameraIds}
            />
            <FormControlLabel control={<Checkbox checked={useSpot} onChange={toggleUseSpot} />} label="Use spot" />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>Cancel</Button>
            <Button type="submit">{isEdit ? 'Update' : 'Create'}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
