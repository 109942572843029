import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSchedule, updateSchedule, getSchedules, deleteSchedule } from 'api';
import { StatusCodes } from 'enums';
import { RejectedValue, ScheduleRequest } from 'interfaces';

export const fetchSchedules = createAsyncThunk<Array<string>, void, { rejectValue: RejectedValue }>(
  'schedules/fetchSchedules',
  async (_, thunkAPI) => {
    const schedulesRes = await getSchedules();

    if (schedulesRes.status && schedulesRes.status !== StatusCodes.OK) {
      return thunkAPI.rejectWithValue({ error: schedulesRes.statusText });
    }

    return schedulesRes.data;
  },
);

export const handleCreateSchedule = createAsyncThunk<
  void,
  {
    requestBody: ScheduleRequest;
    jobId: string;
  },
  { rejectValue: RejectedValue }
>('schedules/createSchedule', async ({ requestBody, jobId }, thunkAPI) => {
  const createScheduleRes = await createSchedule(requestBody, jobId);

  if (createScheduleRes.status && createScheduleRes.status !== StatusCodes.OK) {
    return thunkAPI.rejectWithValue({ error: createScheduleRes.statusText });
  }

  return undefined;
});

export const handleUpdateSchedule = createAsyncThunk<
  void,
  {
    requestBody: ScheduleRequest;
    jobId: string;
  },
  { rejectValue: RejectedValue }
>('schedules/updateSchedule', async ({ requestBody, jobId }, thunkAPI) => {
  const updateScheduleRes = await updateSchedule(requestBody, jobId);

  if (updateScheduleRes.status && updateScheduleRes.status !== StatusCodes.OK) {
    return thunkAPI.rejectWithValue({ error: updateScheduleRes.statusText });
  }

  return undefined;
});

export const handleDeleteSchedule = createAsyncThunk('schedules/delete', async (jobId: ScheduleRequest['schedule']) => {
  const { data } = await deleteSchedule(jobId);
  return data;
});
